import React from 'react';
import styled from 'styled-components';
import Data from '../Data';
import star from '../img/Projects/star.png'
import { Text14Bold, Text18Normal } from './FontStyle';


const BoxWrapper = styled.div`
   display:grid;
   grid-template-columns: repeat(3, 1fr);
   grid-gap:30px;
   padding:50px 0px;
   @media (min-width:768px) and (max-width:1020px){
    grid-template-columns: repeat(1, 1fr);
   }
   @media (min-width:1021px) and (max-width:1440px){
       grid-template-columns: repeat(2, 1fr);
   }
`
const Box = styled.div`
   background:#F8F8F8;
   padding:40px;
   border: ${props => `2px solid ${props.border}`};
   border-radius:40px;
   color:#161616;
   transition: all 0.5s;
   transition-delay: 0.05s;
   cursor:pointer;
   &:hover{
    border:3px solid${props => props.hoverBorder};
    transform: scale(1.02) translateY(-0.45rem);
   }
   @media (max-width:768px){
    padding:24px;
    transition:none;
    &:hover{
        transform:none;
      }
    }
   .subTitle{
       white-space:pre-line;
       font-size:32px;
       font-weight:bold;
       color:#161616;
       margin:0px;
       padding-top:16px;
    @media (max-width:768px){
        font-size:20px;
    }
   }
   .academyName{
       font-weight:bold;
       color:${props => props.color}!important;
   }
   .grey{
       color:#BFBFBF!important; 
       padding:24px 0px 16px 0px;
    }
   ul{
       margin:0px;
       padding:0px;
       list-style:none;
       li{
        display: flex;
        align-items: baseline; 
        padding-bottom:10px;
        font-size:18px;
        @media (max-width:768px){
            font-size:14px;
          }
       }
       li::before{
           content:"";
           text-indent:-999em;
           display:inline-block;
           width:16px;
           height:16px;
           background:url(${star}) no-repeat;
           padding-right:10px;
        }
    }
`
export const SingleBox = ({ handlePopUp, hoverBorder, border, card }) => (
    <Box border={border} hoverBorder={hoverBorder} onClick={() => handlePopUp()}>
        <Text14Bold color={card.color}>{card.type}</Text14Bold>
        <p className="subTitle">{card.title}</p>
        <Text14Bold className="grey">{Data.projects.about}</Text14Bold>
        <Text18Normal>{card.text}</Text18Normal>
        <Text14Bold className="grey">{Data.projects.process}</Text14Bold>
        <ul>
            {card.bulletPoints.map((bullet, i) => (
                <li key={i}>{bullet}</li>
            ))}
        </ul>
    </Box>
)
const CardBox = ({ handlePopUp, cardsToShow, hoverBorder, border }) => {
    return (
        <BoxWrapper >
            {cardsToShow.map((card, index) => (
                <SingleBox card={card} key={index} handlePopUp={handlePopUp} hoverBorder={hoverBorder} border={border} />
            ))}
        </BoxWrapper>
    );
}

export default CardBox;