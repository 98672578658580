import { useEffect, useState } from 'react';
import { API } from '../../Consts/Api';

const useFetch = (url) => {
    const [data, setData] = useState()

    useEffect(() => {
        API.axios.get(url)
            .then(res => setData(res.data))
            .catch(err => {
                console.log(err);
            })
    }, [url])

    return data;
}

export default useFetch;


