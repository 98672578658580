const PlusIcon = (props) => (

    <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 31.254 31.254">
        <g id="plus" transform="matrix(0.695, 0.719, -0.719, 0.695, 15.943, -2.506)">
            <g id="Group_309" data-name="Group 309" transform="translate(-8.887 13.582) rotate(-47)">
                <path id="Path_93" data-name="Path 93" d="M29.522,14.161H16.561V1.2a1.2,1.2,0,1,0-2.4,0V14.161H1.2a1.2,1.2,0,1,0,0,2.4H14.161V29.522a1.2,1.2,0,0,0,2.4,0V16.561H29.522a1.2,1.2,0,0,0,0-2.4Z" fill={props.fill} />
            </g>
        </g>
    </svg>
)
export default PlusIcon