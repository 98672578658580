import React from 'react';
import styled from 'styled-components';
import Data from '../../Data';
import DropDownMobileFilter from './DropDownMobileFilter';
import { FilterMenu } from './FilterMenu';

const FilterSideMenuWrapper = styled.div`
    width:30%;
    position:sticky;
    top:0px;
    float:left;
    ul{
        list-style: none;
        padding:0px;
    }
    @media (max-width:768px){
        display:none;
    }
`
const FilterSideBar = ({ subDomain, clickedElement, handleClick, resetCounter, props, }) => {

    return (
        <>
            <DropDownMobileFilter subDomain={subDomain} filterMenuList={Data.filterMenu} handleClick={handleClick} clickedElement={clickedElement} resetCounter={resetCounter} />
            <FilterSideMenuWrapper>
                <FilterMenu subDomain={subDomain} filterMenuList={Data.filterMenu} clickedElement={clickedElement} handleClick={handleClick} resetCounter={resetCounter} />
            </FilterSideMenuWrapper >
        </>
    );
}

export default FilterSideBar;