import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Text18Normal, Text24Bold } from '../FontStyle';

import arrow from '../../img/PopUp/arrow-up.png'
import Data from '../../Data';

const Form = styled.form`
    height:100%;
`
const FormSection = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 0.3fr;
  grid-column-gap:5%;
  input[type=number] {
        -moz-appearance: textfield;
   }
   input::-webkit-outer-spin-button,
   input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input{
      border:0px;
      border-bottom:2px solid #A8A8A8;
      width:100%;
      font-family: 'Montserrat';
      font-size:18px;
      color:#C2C2C2;
      padding-bottom:10px;
      background: transparent;
    &:focus {
        outline:none;
    }
    ::placeholder,
    ::-webkit-input-placeholder {
          font-size:18px;
          font-weight:normal;
          color:#C2C2C2;
          font-family: 'Montserrat';
        }
    }
  @media(max-width:768px){
    grid-template-columns: 1fr;
    grid-column-gap:0;
    input{
        border-bottom:1px solid #A8A8A8;
        font-size:12px;
        ::placeholder,
        ::-webkit-input-placeholder {
              font-size:12px;
            }   
        }
  }
  @media (min-width:768px) and (max-width:1100px){  
        input{
            font-size:14px;
            ::placeholder,
            ::-webkit-input-placeholder {
                  font-size:14px;
                }   
            }
    }
  @media(max-height:768px){
    margin-top:20px;
  }
`
const DropDownMenu = styled.div`
    position:absolute;
    background:#FFFFFF;
    left: 0;
    right: 0;
    z-index: 1;
    border:1px solid #707070;
    padding:10px;
    @media (max-width:768px){
        padding: 10px 5px;
    }
`
const DropDown = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 20%;
    margin-top:5%;
    .dropDownWrapper{
        position:relative;
    }
    @media(max-width:768px){
      padding-top: 30px;
      height:auto;
    }
`
const DropDownText = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom:2px solid #A8A8A8;
    cursor:pointer;
    .textCheckbox{
        font-family: 'Montserrat';
        font-size: 18px;
        color:#C2C2C2;
    }
    input{
        border-bottom:none !important;
        padding-bottom:7px !important;
    }
    .imageArrow{
        width:10%;
        text-align:right;
        padding:10px;
        .rotate{
            transform: rotateZ(180deg);
            transition: all 0.6s ease 0s;
        }
        img{
            cursor:pointer;
            transition: all 0.6s ease 0s;
        }
    } 
    @media(max-width:768px){
        border-bottom:1px solid #A8A8A8;
        .textCheckbox{
            font-size: 12px;
        }
        .imageArrow{
            width:19px;
            height:11px;
            img{
                width:100%;
                height:100%;
            }
        }
    }
    @media(min-width:768px) and (max-width:1100px){
        .textCheckbox{
            font-size: 14px;
        }
    }
`
const Label = styled.label`
    height: 20%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top:5%;
    box-sizing:border-box;
    @media (max-width:768px){
        padding-top: 30px;
        height:auto;
    }
    `
const LabelCheckBox = styled.label`
   display: flex;
   margin:15px 15px;
   align-items:center;
   input[type=checkbox] {width:18px; height:18px;margin:0px}
   @media (max-width:768px){
    input[type=checkbox] {width:15px; height:15px;}
    }
`
const LabelTextArea = styled.div`
   height:50%;
   display: flex;
   flex-direction: column;
   justify-content: flex-end;
   div{
       height:50%;
       padding-bottom:8px;
       box-sizing: border-box;
       display:flex;
       flex-direction: column;
       justify-content: flex-end;
    }
   textarea{
       height:50%;
       padding:10px;
       border:1px solid #A8A8A8;
       box-sizing: border-box;
       font-size:18px;
       color:#C2C2C2;
       resize: none;
       &:focus{
        outline:none;
       }
       ::placeholder,
       ::-webkit-input-placeholder{
             font-size:18px;
             font-weight:normal;
             color:#C2C2C2;
             font-family: 'Montserrat';
           }
       }
   }
   .smallTextAbove{
        color:#FBD132;
        font-size:14px;
        text-align:right;
        margin:0px;
        padding-bottom:10px;
   }
   @media (min-width:768px) and (max-width:1100px){  
    textarea{
        ::placeholder,
        ::-webkit-input-placeholder{
              font-size:14px;
            }
        }
    }
   }
   @media(max-width:768px){
    padding-top: 30px;
    height:auto;
    .smallTextAbove{
        font-size:12px;
    }
    textarea{
        height:100px;
        font-size:14px;
        ::placeholder,
        ::-webkit-input-placeholder{
              font-size:12px;
            }
        }
   }
`
const Button = styled.button`
    padding:5px 7px;
    margin:3px;
    border:1px solid #A8A8A8;
    background:transparent;
    font-size:16px;
    font-weight: normal;
    font-family:Montserrat;
    cursor:pointer;
    color:#A8A8A8;
    &:focus{
       outline:none;
    }
    @media (max-width:768px){
        font-size:12px;
    }
`
const ButtonWrapper = styled.div`
    grid-column-start: 1;
    grid-column-end: 3;
    margin-top:15px;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-self: center;
    .buttonForm{
        padding: 10px 50px;
        border-radius: 50px;
        font-weight: bold;
        font-size: 24px;
        cursor: pointer;
        margin-top: 5px;
        margin-bottom: 10px;
    }
    .buttonDisabled{
        background:transparent;
        color:#C2C2C2;
    }
    .buttonDisabled{
        border:3px solid #C2C2C2;
      }
    @media(max-width:768px){
        grid-column-start: auto;
        grid-column-end: auto;
        padding:20px 0px;
        box-sizing:border-box;
        .buttonForm{
            padding: 10px 20px !important;
        }
    }
`
export const SuccessMessage = styled.button`
  padding: 10px 15px;
  background:#FBD132;
  color:#ffffff;
  border-radius:50px;
  font-size:16px;
  text-align:center;
  border:none;
  margin: 0 auto;
  &:focus{
    outline:none;
  }
  @media (max-width:768px){
    font-size:12px;
}
`

const HireAStudent = ({ academyList, postData, handleInputChange, data, isVisible, subDomain }) => {
    const [toogle, setToogle] = useState({ urgent: false, student: false });
    const [list, setList] = useState([]);
    const [urgency, setUrgency] = useState({});
    const [sliced, setSliced] = useState([])
    const [specifications, setSpecification] = useState("")

    // toogle DropDown menu
    const toogleDrobDown = (name) => {
        setToogle({ [name]: !toogle[name] })
    }

    // on checkbox change
    const handleCheckboxChange = (e) => {
        // if element is not in list ,push in list ,if is already there delete it
        if (list.includes(e.target.name)) {
            let filterd = list.filter(element => element !== e.target.name)
            return setList(filterd)
        } else return setList([...list, e.target.name])
    }

    // On list change, if list have more that 2 elements, put ... at every other click
    useEffect(() => {
        let sliced = list.slice(0, 1);
        if (list.length > 1) {
            setSliced([...sliced, "..."])
        } else { setSliced(list) }
    }, [list])

    const reset = () => {
        setUrgency("")
        setList([])
        setSpecification("")
    }
    const handleFocus = () => {
        setToogle({ urgent: false, student: false })
    }
    return (
        <Form onSubmit={(e, data) => { e.preventDefault(); reset(); postData({ list, urgency: urgency, type: subDomain === "hiring" ? "HIRE" : subDomain === "b2b" ? "B2B" : "INNOVATE", specifications: specifications }) }}>
            <FormSection>
                <div className="right">
                    {academyList.map((element, index) => (
                        <Label key={index}>
                            <Text24Bold>{element.label}</Text24Bold>
                            <input name={element.name} value={data[element.name]} type={element.type} autoComplete="off" placeholder={element.placeholder} onChange={(e) => handleInputChange(e)} onFocus={() => handleFocus()} />
                        </Label>
                    ))}
                </div>
                <div className="left">
                    <DropDown>
                        <Text24Bold>{Data.hirePopUp[subDomain].profileStudent}</Text24Bold>
                        <div className="dropDownWrapper">
                            <DropDownText onClick={() => toogleDrobDown("student")}>
                                <div className="textCheckbox">
                                    {list.length > 0 ?
                                        sliced.map((element, index) => <Button key={index} disabled >{element}</Button>)
                                        :
                                        <span>{Data.hirePopUp[subDomain].profileStudentPlaceholder}</span>
                                    }
                                </div>
                                <div className="imageArrow">
                                    <img src={arrow} alt="" className={toogle.student ? "rotate" : ""} />
                                </div>
                            </DropDownText>
                            <DropDownMenu className={toogle.student ? "show" : "hide"}>
                                {Data.academy.map((element, i) => (
                                    <LabelCheckBox key={i}>
                                        <input
                                            type="checkbox"
                                            checked={list.includes(element)}
                                            name={element}
                                            onChange={(e) => handleCheckboxChange(e)} />
                                        <Text18Normal margin>{element}</Text18Normal>
                                    </LabelCheckBox>
                                ))}
                            </DropDownMenu>
                        </div>
                    </DropDown>
                    <DropDown>
                        <Text24Bold>{Data.hirePopUp[subDomain].urgency}</Text24Bold>
                        <div className="dropDownWrapper">
                            <DropDownText onClick={() => toogleDrobDown("urgent")} >
                                <div className="textCheckbox">
                                    {subDomain === "hiring" || subDomain === "b2b" ?
                                        urgency.id ? <Button disabled>{urgency.value}</Button>
                                            :
                                            <span>{Data.hirePopUp.urgencyPlaceholder}</span>
                                        :
                                        urgency.id ?
                                            (urgency.id === 1 ? <input type="number" placeholder={Data.hirePopUp[subDomain].budget} onChange={(e) => setUrgency({ ...urgency, "budget": e.target.value })} autoComplete="off" />
                                                :
                                                <Button disabled>{urgency.value}</Button>)
                                            :
                                            <span>{Data.hirePopUp.urgencyPlaceholder}</span>
                                    }
                                </div>
                                <div className="imageArrow">
                                    <img src={arrow} alt="" className={toogle.urgent ? "rotate" : ""} />
                                </div>
                            </DropDownText>
                            <DropDownMenu className={toogle.urgent ? "show" : "hide"}>
                                {Data.hirePopUp[subDomain].urgentList.map((element, i) => (
                                    <LabelCheckBox key={i}>
                                        <input type="checkbox" checked={element.id === urgency.id} onChange={(e) => setUrgency({ id: element.id, value: element.value })} />
                                        <Text18Normal margin>{element.value}</Text18Normal>
                                    </LabelCheckBox>
                                ))}
                            </DropDownMenu>
                        </div>
                    </DropDown>
                    <LabelTextArea>
                        <div>
                            <Text24Bold>{Data.hirePopUp[subDomain].specification}</Text24Bold>
                            <p className="smallTextAbove">{Data.hirePopUp.specificationOptional}*</p>
                        </div>
                        <textarea name="specifications" value={specifications} placeholder={Data.hirePopUp[subDomain].specificationPlaceholder} onChange={(e) => setSpecification(e.target.value)} onFocus={() => handleFocus()} />
                    </LabelTextArea>
                </div>
                <ButtonWrapper isVisible={isVisible}>
                    {isVisible.success && <SuccessMessage>{Data.hirePopUp.successMessage}</SuccessMessage>}
                    {isVisible.errorData && <SuccessMessage>{Data.hirePopUp.errorData}</SuccessMessage>}
                    {isVisible.error && <SuccessMessage>{Data.hirePopUp.error}</SuccessMessage>}
                    {data.company && data.contact && data.email && data.number && urgency.value && list !== [] ?
                        <button className={`button ${subDomain} buttonForm`} type="submit" >{Data.hirePopUp[subDomain].button}</button>
                        :
                        <button className={`button ${subDomain} buttonDisabled buttonForm`} disabled>{Data.hirePopUp[subDomain].button}</button>
                    }
                </ButtonWrapper>
            </FormSection>
        </Form>
    );
}

export default HireAStudent;
