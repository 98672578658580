import React, { useState, useEffect } from 'react';
import PopUp from '../PopUp/PopUp';
import AskForAdvice from './AskForAdvice.js';
import HireAStudent from './HireAStudent.js';
import ExitButton from '../PopUp/ExitButton';
import styled from 'styled-components';
import { Text34Bold } from '../FontStyle';
import Data from '../../Data';
import { API } from '../../Consts/Api';
import { Link } from "@reach/router";

// image
import popUpShape from '../../img/PopUp/popUpHire.png'

const HirePopUpSection = styled.div`
    width:70%;
    height:90vh;
    position: fixed;
    top: 50%;
    left:50%;
    transform: translate(-50%,-50%);
    margin: 0;
    z-index: 13;
    background:#FFFFFF;
    .header{
        display:flex;
        justify-content:center;
    }
    @media (max-width:768px){
        max-height:80vh;
        overflow-y: scroll;
        width:95%;
    }
    @media (min-width:768px) and (max-width:1200px){
        width:90%;
    }
    @media (max-height:700px){
        max-height:75vh;
        overflow-y: scroll;
    }
    `
const HirePopUpWrapper = styled.div`
    width:80%;
    margin:0 auto;
    height: 100%;
    padding: 80px 0px;
    box-sizing: border-box;
    .title{
        margin:0px;
        margin-right:5%;
        cursor:pointer;
        color:#3A3A3A;
    }
    .border{
        border-bottom:5px solid #FBD132;
        padding-bottom:25px;
    }
    @media (max-width:425px){
        padding:80px 24px 50px 24px !important;
    }
    @media (max-width:768px){
        width:100%;
        padding:80px 40px 50px 40px;
        .border{
            border-bottom:3px solid #FBD132;
            padding-bottom:10px;
        }
    }
`
const Background = styled.div`
    position: absolute;
    background-image:url(${popUpShape});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: -1;
    height: 100%;
    width: 100%;
    @media (max-width:500px){
        background-size: 160% 80%;
        top:50px;
    }
    @media (max-height:600px){
        display:none;
    }
`
const HirePopUp = ({ handlePopUp, subDomain, headline, id, iamIn }) => {
    const [show, setShow] = useState(false);
    const [isVisible, setIsVisible] = useState({ success: false, error: false, errorData: false })
    const [enterData, setEnterData] = useState({
        "company": "", "contact": "", "email": "", "number": ""
    })

    useEffect(() => {
        let timer = setInterval(function () {
            if (isVisible.success || isVisible.error || isVisible.errorData) {
                if (isVisible.success) {
                    setIsVisible({ success: false });
                } else if (isVisible.errorData) {
                    setIsVisible({ errorData: false });
                }
                else if (isVisible.error) {
                    setIsVisible({ error: false });
                } else {
                    clearInterval(timer)
                }
            }
        }, 7000);
    }, [isVisible])

    const handleInput = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        setEnterData({ ...enterData, [name]: value });
    }
    const postResults = (data) => {

        let formData = new FormData();
        formData.append("company_name", enterData.company)
        formData.append("contact_name", enterData.contact);
        formData.append("contact_email", enterData.email);
        // if type is HIRE and B2B it need's to send contact number,otherwise due date for innovation
        data.type === "HIRE" || data.type === "B2B" ? formData.append("contact_number", enterData.number) :
            formData.append("due_date", enterData.number)
        formData.append("type", data.type);
        // if type is B2B it need's to send empoyees number,otherwise urgency id (1-yes ,2-no)
        data.type === "B2B" ? data.urgency && formData.append("employees_num", data.urgency.value) :
            data.urgency && formData.append("urgency", data.urgency.id);// data.urgency.id);
        data.list && formData.append("student_profile", JSON.stringify(data.list));
        data.specifications && formData.append("specifications", data.specifications);
        // if type is INNOVATE ,if it's selected yes it needs to send a budget
        data.type === "INNOVATE" && data.urgency.budget && formData.append("budget", data.urgency.budget);

        API.axios.post(API.routes.postCompany, formData)
            .then(response => { setIsVisible({ success: true }); setEnterData({ "company": "", "contact": "", "email": "", "number": "" }) })
            .catch(error => {
                //validation errors
                if (parseInt(error.response.status) === 422) {
                    setIsVisible({ errorData: true })
                } else {
                    setIsVisible({ error: true })
                }

            })
    }

    return (
        <>
            <PopUp />
            <HirePopUpSection>
                <Background />
                <HirePopUpWrapper>
                    <div className="header">
                        <Text34Bold className={show ? "title" : "title border"} onClick={() => setShow(false)}>{Data.hirePopUp[subDomain].headline}</Text34Bold>
                        {subDomain === 'hiring' && <Text34Bold className={show ? "title border" : "title"} onClick={() => setShow(true)}>{Data.hirePopUp[subDomain].headlineRight}</Text34Bold>}
                    </div>
                    {subDomain === "b2b" ? <Link to={iamIn === "project" ? `/Project/${id}` : "/"}><ExitButton /></Link> : <ExitButton handlePopUp={handlePopUp} />}
                    {show ?
                        <AskForAdvice
                            academyList={Data.hirePopUp[subDomain].academyList}
                            handleInputChange={handleInput}
                            postData={(data) => postResults(data)}
                            data={enterData}
                            isVisible={isVisible}
                            subDomain={subDomain}
                        />
                        :
                        <HireAStudent
                            academyList={Data.hirePopUp[subDomain].academyList}
                            postData={(data) => postResults(data)}
                            handleInputChange={handleInput}
                            data={enterData}
                            isVisible={isVisible}
                            subDomain={subDomain}
                        />
                    }
                </HirePopUpWrapper>
            </HirePopUpSection>
        </>
    );
}

export default HirePopUp;


