import React from 'react';
import styled from 'styled-components';


const PopUpWrapper=styled.div`
    width: 100%;
    height: 100vh;
    top:0;
    left:0;
    z-index: 13;
    position: fixed;
    font-style: normal;
    font-weight: normal;
    color: #FFFFFF;  
    background-color:${props => props.background ? props.background : "#161616"};
    opacity:80%;
`

const PopUp = ({background}) => {
    return ( 
        <PopUpWrapper background={background}/>
     );
}
 
export default PopUp;