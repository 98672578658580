import React from 'react';
import styled from 'styled-components';
// import Data from '../../Data';
// import fullscreen from '../../img/LatestWork/gallery/fullscreen.png'
// import usePopUp from '../Hooks/usePopUp';
// import ModalSlider from './ModalSlider';


const GalleryBlock = styled.div`
    height:auto;
    width: 100%;
    .textUnderImage{
        padding:24px;
        width:80%;
        margin:0 auto;
        font-size:14px;
        color:#A8A8A8;
        font-weight: bold;
        display: flex;
        justify-content: flex-end;
        align-items: center;
       .openPopUp{
            margin-left:16px;
            cursor:pointer;
        }
    }
    @media (max-width:768px){
        .textUnderImage{
            width:100%;
            font-size:12px;
            padding:21px;
            box-sizing: border-box;
        }
    }
`

const Background = styled.div`
   max-height:803px;
   img{
       width:100%;
       height:100%;
       max-height:803px;
    }
`

const ModalGallery = ({ background, whereIsIt, projectToShow }) => {
    // const [popUp, handlePopUp] = usePopUp();

    return (
        <>
            {/* {popUp && <ModalSlider handlePopUp={handlePopUp} projectToShow={projectToShow} />} */}
            <GalleryBlock>
                {/* <Background onClick={whereIsIt ? null : handlePopUp}> */}
                <Background>
                    <img src={background} alt="" />
                </Background>
                {/* {whereIsIt ? null : <p className="textUnderImage">{Data.ourLatestWork.galleryTextUnderImage}<img className="openPopUp" src={fullscreen} onClick={() => handlePopUp()} alt="" /></p>} */}
            </GalleryBlock>
        </>
    );
}

export default ModalGallery;