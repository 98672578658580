import React from 'react';
import styled from 'styled-components';
import { Text14Bold, Text24Bold } from '../FontStyle';
import Data from '../../Data';
import Slider from '../Slider';
import PopUp from '../PopUp/PopUp';
// images
import next from '../../img/LatestWork/gallery/next.png'
import prev from '../../img/LatestWork/gallery/prev.png'
import fullscreenClose from '../../img/LatestWork/gallery/fullscreenClose.png'

const SliderGalleryWrapper = styled.div`
  width: 100%;
  position: fixed;
  top: 50%;
  left:50%;
  transform: translate(-50%,-50%);
  z-index:10;

`
const Grid = styled.div`
    .sliderImage{
        width:100%;
    }
    .text{
        color:#F8F8F8; 
        padding:0px;
    }
    .textAbove{
        padding-top:35px;
        padding-bottom:27px;
        text-align: right;
    }
    .textUnder{
        padding-top:24px;
        padding-bottom:10px;
    }
    .closePopUp{
        margin-left:16px;
    }
    .academy{
        font-size:16px;
        font-weight:500;
        color:#BFBFBF;
    }
    .slideShowImage{
        width: 100%;
        object-fit: cover;
        object-position: center center;
    }
    .swiper-button-next{
        display:block;
        width: 40%;
        height: 24px;
        background-image:url(${next}) !important;
        background-size: contain;
        outline:0;
    }
    .swiper-button-prev{
        display:block;
        width: 40%;
        height: 24px;
        background-image:url(${prev}) !important;
        background-size: contain;
        outline:0;
        }

    .swiper-slide-next,.swiper-slide-prev{
        opacity:0.39;
        .textAbove,.textUnder{
            visibility: hidden;
        }
    }
    .swiper-slider .swiper-container{
        display:grid;
    }
    .swiper-slider .swiper-slide{
        display: flex;
        align-self: strech;
        transition: all .5s ease-in-out;
    }
    .swiper-slider .swiper-slide.swiper-slide-next{
        transform: scale(0.8);
    }
    .swiper-slider .swiper-slide.swiper-slide-prev{
        transform: scale(0.8)
    }
    @media (max-width:768px){
        .swiper-button-prev,.swiper-button-next{
            display:none;
        }
    }
`

export const ModalSliderData = ({ data, handlePopUp }) => (
    <div className="sliderImage">
        <div className="textAbove">
            <Text14Bold className="text" onClick={handlePopUp}>{Data.ourLatestWork.exitFullScreen}
            <img className="closePopUp" src={fullscreenClose} alt="" />
            </Text14Bold>
        </div>
        <img src={require(`../../img/LatestWork/gallery/${data.id}.jpg`).default} className="slideShowImage" alt="" />
        <div className="textUnder">
            <Text24Bold className="text">{data.name}</Text24Bold>
            <p className="academy">{data.academy}</p>
        </div>
    </div>
    
)
const ModalSlider = ({ handlePopUp,projectToShow }) => {
    return (
        <>
            <PopUp />
            <SliderGalleryWrapper>
                <Grid>
                    <Slider whereIsIt="latesWorkModalGallery" slideImages={projectToShow.projectData.modalData}  handlePopUp={handlePopUp} />
                </Grid>
            </SliderGalleryWrapper>
        </>
    );
}

export default ModalSlider;


