import React from 'react';
import styled from 'styled-components';
import HirePopUp from '../Form/HirePopUp';
import usePopUp from '../Hooks/usePopUp';
import Data from '../../Data';
import FooterContact from './FooterContact';
import { Headline50 ,Text20Normal} from '../FontStyle';

const FooterWrapper = styled.div`
    background:#000000;
    color:#FFFFFF;
    padding:90px 0px 0px;
    @media (max-width:1024px){
        padding:40px 0px 0px;
    }
`
const FooterBlockWrapper = styled.div`
    width:80%;
    margin:0 auto;
    box-sizing: border-box;
    .text{
        color:#FFFFFF;
        line-height: 40px;
        padding:95px 0px;
        a{
            color:#FBD132;  
        }
    }
    @media (max-width:768px){
        width:100%;
        padding:0px 40px 0px 40px;
        .text{
            display:none;  
        }
    }
    @media (max-width:425px){
        width:100%;
        padding:0px 24px 0px 24px !important;
    }
`
const MainText = styled.div`
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom:100px;
    border-bottom:1px solid #B3B4B5;
    button{
        color:#FFFFFF;
    }
    .title{
        width:70%;
        color:#FFFFFF;
    }
    @media (max-width:905px){
        flex-direction:column;
        align-items: flex-start;
        padding-bottom:50px;
        .title{
            width:100%;
            padding-bottom:30px;
        }
    }
    @media (min-width:426px) and (max-width:1440px){
        button{
            font-size:22px!important;
        }
    }
    @media (max-width:425px){
        .title{
           font-size:24px;
        }
    }
`

const Footer = ({ subDomain, title, button }) => {
    const [popUp, handlePopUp] = usePopUp()

    return (
        <>
            { popUp && <HirePopUp handlePopUp={handlePopUp} subDomain={subDomain} />}
            <FooterWrapper>
                <FooterBlockWrapper>
                    <MainText>
                        <Headline50 className="title">{title}</Headline50>
                        <button className={`button ${subDomain}`} onClick={() => handlePopUp()}>{button}</button>
                    </MainText>
                    <Text20Normal className="text" dangerouslySetInnerHTML={{ __html: Data.footer.body.replace('{Brainster}', `<a href=${Data.footer.brainsterLink} className="link">${Data.footer.brainster}</a>`) }}></Text20Normal>
                    <FooterContact fill="#FFFFFF" hover="#FBD132" />
                </FooterBlockWrapper>
            </FooterWrapper>
        </>
    );
}

export default Footer;