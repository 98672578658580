import React, { createContext, useEffect, useState } from "react";
import Data from "../Data";

export const Context = createContext({});

export const Provider = (props) => {

    const [selectedLang, setSelectedLang] = useState("en")
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        if (window.innerWidth <= 1024) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [])

    useEffect(() => {
        if (localStorage.getItem("language")) {
            let lang = localStorage.getItem("language")
            setSelectedLang(lang)
            // to force manually a particular language
            Data.setLanguage(lang);
        } else {
            Data.setLanguage("en");
        }
    }, [])

    const selectedLanguages = (language) => {
        localStorage.setItem("language", language)
        setSelectedLang(language)
        Data.setLanguage(language)
    }

    const dataObj = {
        selectedLang,
        selectedLanguages,
        isMobile
    };

    return <Context.Provider value={dataObj}>{props.children}</Context.Provider>;
};
