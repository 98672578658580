import axios from 'axios';

let axiosSetup = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

export const API = {
    axios: axiosSetup,
    routes: {
        getPromotedStudents: "/promotedStudents",
        getHiredStudents: "/hiredStudents",
        getPartners: "/partners",
        postCompany: "/store/hiringProposal",
        // talentMarket: "/talentMarket/{name}",
        company: "/company/{name}"
    },
    storage: process.env.REACT_APP_STORAGE_URL
}