import React from 'react';
import styled from 'styled-components';
import { Text24Normal, H1 } from './FontStyle';
import HirePopUp from './Form/HirePopUp';
import usePopUp from './Hooks/usePopUp';
import NavBar from './NavBar/NavBar';
import NavBarMobile from './NavBar/NavBarMobile';
// images
import logo from '../img/Header/Logo.png';
import arrowDown from '../img/LatestWork/header/arrowDown.png'


const HeaderWrapper = styled.div`
    width: 100%;
    height:auto;
    position:relative;
    font-family:Montserrat;
`
const Background = styled.div`
    width:100%;
    background-image:url(${props => props.background});
    background-size: 100%;
    position:absolute;
    bottom:0;
    right: 0;
    top:0;
    z-index:-1;
    height: 100%;
    background-repeat: no-repeat;
    @media (min-width:2000px){
        background-size:100% 100%; 
    }
    @media (max-width:768px){
        background-image:url(${props => props.backgroundMobile});
        background-size: ${props => props.subDomain === "innovation" || props.subDomain === "hiring" ? "100%" : "100% 350px"};
    }
    @media (max-width:425px){
        background-image:url(${props => props.backgroundMobile});
        background-size: ${props => props.subDomain === "innovation" || props.subDomain === "hiring" ? "100%" : "100% 252px"}
    }
`
const TextWrapper = styled.div`
    height:inherit;
    padding:83px 0px 152px 0px;
    .wrapper{
        width:80%;
        margin:0 auto;
        padding-top:100px;
    }
    .headerTitle{
        margin:24px 0px 40px 0px;
    }
    .headerBody{
        margin-bottom:40px;
        width:90%;
        line-height:40px;
    }
    @media (max-width:425px){
        .wrapper{
          padding-top:142px !important;
        }
    }
    @media (max-width:768px){
        padding:24px 0px 0px;
        .headerBody{
            margin-bottom:40px;
            width:100%;
            line-height:25px;
        }
        .headerTitle{
            margin:0px 0px 30px 0px;
            line-height:49px;
            font-size:40px;
        }
        .wrapper{
            width:100%;
            padding-top:220px;
        }
    }
    @media (min-width:769px)and (max-width:1024px){
        padding:83px 0px 100px 0px;
        .wrapper{
            padding-top:100px;
        }
        .headerBody{
            margin-bottom:40px;
            width:100%;
            line-height:25px;
        }
    }
`
const Text = styled.div`
   width:50%;
   @media (max-width:425px){
    padding:40px 24px 10px 24px !important;
   }
   @media (max-width:768px){
      width:100%;
      background:#FFF;
      padding:40px 40px 10px 40px;
      border-top-left-radius:60px;
      border-top-right-radius:60px;
      box-sizing: border-box;
    }
    @media (min-width:769px)and (max-width:1023px){
       width:75%;
    }
    @media (min-width:1024px)and (max-width:1440px){
      width:65%;
    }
    
`
const Image = styled.div`
   width:15%;
   .logoDestop{
    @media (min-width:769px)and (max-width:1024px){
        width:70%;
    }
      @media (max-width:768px){
        display:none;
    }
   }
`
const TextProject = styled.div`
    width:75%;
    .headerText{
        display: grid;
        grid-template-columns: 0.3fr 1fr;
    }
    .headerProjectTitle{
      margin:0px;
      padding:8px 0px 40px 0px;
    }
    .aboveTitleText{
        font-size:30px;
        color:#A8A8A8;
        padding:40px 0px 0px;
        margin:0px;
        font-weight:500;
    }
    .headerProjectBody{
        padding-right:20px;
    }
    .bounce{
        -moz-animation: bounce 2s infinite;
        -webkit-animation: bounce 2s infinite;
        animation: bounce 2s infinite;
        align-self:end;
    }
    @keyframes bounce {
        0%, 20%, 50%, 80%, 100% {
          transform: translateY(0);
        }
        40% {
          transform: translateY(-30px);
        }
        60% {
          transform: translateY(-15px);
        }
      }
      @media (max-width:1024px){
        width:100%;
      }
      @media (max-width:768px){
        background:#FFF;
        padding:40px 40px 40px 40px;
        border-top-left-radius:60px;
        border-top-right-radius:60px;
        box-sizing: border-box;
        .imageLogo{
            display:none;
        }
        .aboveTitleText{
            font-size:16px;
            color:#3A3A3A;
            padding:0px 0px 0px;
        }
        .headerProjectTitle{
           font-size:40px;
           padding:8px 0px 24px 0px;
        }
        .bounce{
            display:none;
        }
        .headerText{
            grid-template-columns: 1fr;
        }
        .headerProjectBody{
            font-size:16px;
            line-height:28px;
            padding:0px;
        }
    }
    @media (max-width:425px){
        padding:40px 24px 10px 24px !important;
    }
`
const Header = ({ fill, subDomain, title, body, button, background, aboveTitleText, width, backgroundMobile, projectToShow }) => {

    const [popUp, handlePopUp] = usePopUp();

    return (
        <>
            {popUp && <HirePopUp handlePopUp={handlePopUp} subDomain={subDomain} />}
            <HeaderWrapper>
                <Background background={background} backgroundMobile={backgroundMobile} subDomain={subDomain} />
                {subDomain === "project" ?
                    <TextWrapper className={`header ${subDomain}`}>
                        <NavBar subDomain={subDomain} />
                        <NavBarMobile subDomain={subDomain} fill={fill} />
                        <div className="wrapper">
                            <TextProject>
                                <div className="imageLogo">
                                    <img src={logo} alt="Logo" />
                                </div>
                                <p className="aboveTitleText">{aboveTitleText}</p>
                                <H1 className="headerProjectTitle">{title}</H1>
                                <div className="headerText">
                                    <div className="bounce">
                                        <img src={arrowDown} alt="Arrow" />
                                    </div>
                                    <Text24Normal className="headerProjectBody">{body}</Text24Normal>
                                </div>
                            </TextProject>
                        </div>
                    </TextWrapper>
                    :
                    <TextWrapper className={`header ${subDomain}`}>
                        <NavBar subDomain={subDomain} projectToShow={projectToShow} />
                        <NavBarMobile subDomain={subDomain} fill={fill} />
                        <div className="wrapper">
                            <Text width={width} className={`headerText ${subDomain}`}>
                                <Image>
                                    <img className="logoDestop" src={logo} alt="Logo" />
                                </Image>
                                <H1 className="headerTitle">{title}</H1>
                                <Text24Normal className="headerBody">{body}</Text24Normal>
                                <button className={`button ${subDomain}`} onClick={() => handlePopUp()}>{button}</button>
                            </Text>
                        </div>
                    </TextWrapper>
                }
            </HeaderWrapper>

        </>
    );
}

export default Header;