import React from 'react';
import styled from 'styled-components';

import close from '../../img/close.png';

const ExitButtonBlock = styled.div`
    position: absolute;
    top: 37px;
    right: 30px;
    cursor:pointer;
    @media (max-width: 768px){
        width:20px;
        height:20px;
        img{
            width:100%;
            height:100%;
        }
    }
`
const ExitButton = ({handlePopUp}) => {
    return (
        <ExitButtonBlock className="exit-button" onClick={handlePopUp}>
            <img src={close} alt="close" />
        </ExitButtonBlock>);
}

export default ExitButton;