import React from 'react';
import Data from '../../Data';
import Header from '../Header';
import Clients from '../Innovation/Clients';
import HowItWorks from '../Innovation/HowItWorks';
import ModalGallery from '../InnovationLatesWork/ModalGallery';
import HowWillHelpUs from '../B2B/HowWillHelpUs';
import Numbers from '../B2B/Numbers';
import Programs from '../B2B/Programs';
import Advantage from '../B2B/Advantage';
import SloganMove from '../B2B/SloganMove';
import MoreInfo from '../B2B/MoreInfo';
import Footer from '../Footer/Footer';
// images
import headerBackground from '../../img/B2B/Header/headerBackground.jpg'
import backgroundMobile from '../../img/B2B/Header/headerBackgroundMobile.jpg'
import arrow from '../../img/B2B/ourApprouch/arrow.png'
import background from '../../img/B2B/backgroundSectionB2B.jpg'
import logos from '../../img/Clients/logos.png'
import PeopleSay from '../SliderTestimonials/PeopleSay';

const HomeB2B = ({ subDomain }) => {
    return (
        <>
            <Header
                subDomain={subDomain}
                title={Data.headerB2B.title}
                body={Data.headerB2B.text}
                button={Data.headerB2B.button}
                background={headerBackground}
                padding="100px"
                width="60%"
                backgroundMobile={backgroundMobile}
                fill="#F8F8F8"
            />
            <Clients
                subDomain={subDomain}
                logos={logos}
            />
            <HowItWorks
                whereIsIt={subDomain}
                textRight={Data.ourApproachB2b.textRight}
                textRightUnder={Data.ourApproachB2b.textRightUnder}
                title={Data.ourApproachB2b.title}
                iconRight={arrow}
            />
            <HowWillHelpUs />
            <Numbers />
            <Programs subDomain={subDomain} />
            <Advantage subDomain={subDomain} />
            <ModalGallery background={background} whereIsIt='justBackground' />
            <PeopleSay
                title={Data.peopleSay.title}
                testimonials={Data.peopleSay.testimonials}
                whereIsIt="b2b"
                src={process.env.PUBLIC_URL + `img/B2B/PeopleSay`}
            />
            <SloganMove />
            <MoreInfo subDomain={subDomain} />
            <Footer
                subDomain={subDomain}
                title={Data.headerInnovation.title}
                button={Data.headerB2B.button} />
        </>
    );
}

export default HomeB2B;