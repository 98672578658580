import React, { useEffect, useState } from 'react';
import './App.css';
import SelectedCandidates from './components/SelectedCandidates/SelectedCandidat';
import { Router } from "@reach/router"
import HomeHiring from './components/Home/HomeHiring';
import HomeInnovation from './components/Home/HomeInnovation';
import { Provider } from './Context/context';
import HomeLatesWork from './components/Home/HomeLatesWork';
import HomeB2B from './components/Home/HomeB2B';
import ErrorPage from './components/ErrorPage';
import HirePopUp from './components/Form/HirePopUp';
// import Banner from './components/Banner';
// import usePopUp from './components/Hooks/usePopUp';
import figlet from 'figlet';
import standard from 'figlet/importable-fonts/Standard.js'

figlet.parseFont('Standard', standard);

const App = () => {
  const [pathChange, setPathChange] = useState("")
  const [subDomain, setSubDomain] = useState(false)
  // const [popUp, handlePopUp] = usePopUp()

  figlet.text('BRAINSTER.IO', {
    font: 'Standard',
  }, function (err, data) {
    console.log(data)
  });

  useEffect(() => {
    //For local development hardcode to hiring
    if (process.env.REACT_APP_HOST === 'local') {
      setSubDomain('hiring');
    } else {
      setSubDomain(window.location.host.split('.')[0])
    }
  }, [])

  useEffect(() => {
    if (window.location.pathname !== "/" && window.location.pathname !== "/B2B") {
      setPathChange(window.location.pathname)
    }
  }, [])

  // this is for Banner com.
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     handlePopUp()
  //   }, 1000);
  //   return () => clearTimeout(timer);
  // },[])

  return (
    <Provider>
      {/* {popUp && <Banner handlePopUp={handlePopUp} popUp={popUp}/>} */}
      {(subDomain === 'hiring') ?
        <Router>
          <HomeHiring path="/" subDomain="hiring" >
            <HirePopUp path='B2B' subDomain="b2b" />
          </HomeHiring>
          {pathChange !== "" && <SelectedCandidates path={`/${pathChange}`} href={pathChange} />}
          <ErrorPage default />
        </Router>
        :
        (subDomain === 'innovation') ?
          <Router>
            <HomeInnovation path="/" subDomain="innovation" >
              <HirePopUp path='/B2B' subDomain="b2b" />
            </HomeInnovation>
            <HomeLatesWork path='/Project/:id' subDomain="project">
              <HirePopUp path='/B2B' subDomain="b2b" iamIn="project" />
            </HomeLatesWork>
            <ErrorPage default />
          </Router>
          :
          (subDomain === 'b2b') ?
            <Router>
              <HomeB2B path="/" subDomain="b2b" />
              <ErrorPage default />
            </Router>
            :
            null
      }
    </Provider >
  );
}
export default App;
