import React from 'react';
import styled from 'styled-components';
import { Text14Normal, Text24Bold } from '../FontStyle';
import Data from '../../Data';
import { SuccessMessage } from './HireAStudent';

const AskForAdviceSection = styled.div`
  height:40%;
    .text{
       padding: 100px 0px 50px; 
    }
    @media(max-width:768px){
        height:auto;
        padding: 20px 0px;
        .text{
            padding: 50px 0px 30px; 
            color:#3A3A3A;
        }
      }
`
const Form = styled.form`
  height: 100%;
  padding: 50px 0px;
  @media(max-width:768px){
    padding:0px;
  }
`
const InputSection = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5%;
  @media(max-width:768px){
    grid-template-columns: 1fr;
    grid-gap: 0;
  }

`
const Label = styled.label`
   align-self: end;
    input[type=number] {
        -moz-appearance: textfield;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
   input{
      border:0px;
      border-bottom:2px solid #A8A8A8;
      width:100%;
      font-family: 'Montserrat';
      font-size:18px;
      color:#C2C2C2;
      padding-bottom:10px;
      background: transparent;
      &:focus {
        outline:none;
      }
      input:-internal-autofill-selected{
          background-color:transparent !important;
      }
    ::placeholder,
    ::-webkit-input-placeholder {
          font-size:18px;
          font-weight:normal;
          color:#C2C2C2;
          font-family: 'Montserrat';
        }
      }
    }
    @media (max-width:768px){
        padding-top: 30px;
        height:auto;
        input{
            border-bottom:1px solid #A8A8A8;
            font-size:12px;
            ::placeholder,
            ::-webkit-input-placeholder {
                  font-size:12px;
                }   
            }
    }
`
const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .buttonForm{
      padding: 10px 50px;
      border-radius: 50px;
      font-weight: bold;
      font-size: 24px;
      cursor: pointer;
      margin-top: 5px;
      margin-bottom: 10px;
    }
    .buttonDisabled{
      background:transparent;
      color:#C2C2C2;
    }
    .buttonDisabled{
      border:3px solid #C2C2C2;
    }
    @media(max-width:768px){
      .buttonForm{
        width:auto;
        padding: 10px 20px !important;
      }
  }
`
const AskForAdvice = ({ academyList, handleInputChange, postData, data, isVisible, subDomain }) => {
  return (
    <AskForAdviceSection>
      <Form onSubmit={(e, data) => { e.preventDefault(); postData({ type: "ADVICE" }) }}>
        <InputSection>
          {academyList.map((element, index) => (
            <Label key={index}>
              <Text24Bold>{element.label}</Text24Bold>
              <input type={element.type} value={data[element.name]} name={element.name} placeholder={element.placeholder} onChange={(e) => handleInputChange(e)} autoComplete="off" />
            </Label>
          ))}
        </InputSection>
        <Text14Normal className="text">{Data.askForAdvice.paragraphText}</Text14Normal>
        <ButtonWrapper isVisible={isVisible}>
          {isVisible.success && <SuccessMessage>{Data.hirePopUp.successMessage}</SuccessMessage>}
          {isVisible.errorData && <SuccessMessage>{Data.hirePopUp.errorData}</SuccessMessage>}
          {isVisible.error && <SuccessMessage>{Data.hirePopUp.error}</SuccessMessage>}
          {data.company && data.contact && data.email && data.number ?
            <button className={`button ${subDomain} buttonForm`} type="submit" >{Data.hirePopUp[subDomain].button}</button>
            :
            <button className={`button ${subDomain} buttonDisabled buttonForm`} disabled>{Data.hirePopUp[subDomain].button}</button>
          }
        </ButtonWrapper>
      </Form>
    </AskForAdviceSection >
  );
}

export default AskForAdvice;