import React from 'react';
import styled from 'styled-components';
import Data from '../../Data.js';
import HirePopUp from '../Form/HirePopUp';
import usePopUp from '../Hooks/usePopUp.js';
import SingleCard from './SingleCard';


const CardWrapper = styled.div`
    width:60%;
    float:right;
    @media (max-width:768px){
        width:100%;
    }
`
const Button = styled.button`
   border:4px solid #FBD132;
   background:transparent;
   border-radius: 50px;
   font-weight: bold;
   font-size: 28px;
   font-family:Montserrat;
   cursor:pointer;
   padding:12px 25px;
   margin:50px auto;
   display:flex;
   &:focus{
       outline:none;
   }
   @media (max-width:425px){
    font-size: 18px;
  }
  @media (min-width:426px) and (max-width:1440px){
   font-size: 24px;
  }
`
const Cards = ({ filtered, handleMore, hideButton, color ,subDomain }) => {
    const [popUp, handlePopUp] = usePopUp(false)
    return (
        <>
            {popUp && <HirePopUp handlePopUp={handlePopUp} subDomain={subDomain}/>}
            <CardWrapper>
                {filtered && filtered.map((card, i) => (
                    <SingleCard card={card} key={i} color={color} />
                ))
                }
                {hideButton ?
                    <Button onClick={() => handlePopUp()}>{Data.header.button}</Button>
                    :
                    <Button onClick={() => handleMore()}>{Data.cards.buttonTextMore}</Button>
                }

            </CardWrapper>

        </>
    )
}

export default Cards;