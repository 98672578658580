import React from 'react';
import styled from 'styled-components';
import Data from '../../Data';
import { Text20Bold } from '../FontStyle';
import GalleryImage from './GalleryImage';

const SimilarProjectsWrapper = styled.div`
    width:80%;
    margin:0 auto;
    position: relative;
    top: -18px;
    padding-bottom:90px;
    .title{
        font-size:40px;
        font-weight:bold;
        color:#161616;
        margin:0px;
        margin-bottom:40px;
    }
    @media (max-width:768px){
      width: 100%;
      padding:20px 40px;
      box-sizing: border-box;
      .title{
        font-size:26px;  
      }
    }
    @media (max-width:425px){
        padding: 20px 24px !important;
    }
`
const Box = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
    .sliderImage{
        text-decoration: none;
    }
    img{
        width:100%;
    }
    .academyName{
            color:#FE7F4A;
            padding:24px 0px 10px;
        }
    .subTitle{
            font-size:28px;
            font-weight:bold;
            color:#161616;
            margin:0;
        }
    @media (max-width:768px){
        grid-template-columns: 1fr;
        .subTitle{
            font-size:22px;
        }
    }
    @media (max-width:425px){
        .subTitle{
            font-size:18px;
        }
    }
    
`
export const ProjectInfo = ({ data }) => (
    <a href={data.link} className="sliderImage">
        <GalleryImage linkImage={require(`../../img/LatestWork/SimilarProjects/${data.linkImage}.png`).default} />
        <Text20Bold className="academyName">{data.academyName}</Text20Bold>
        <p className="subTitle">{data.subTitle}</p>
    </a>
)
const SimilarProjects = ({ projectToShow }) => {
    return (
        <SimilarProjectsWrapper>
            <p className="title">{Data.ourLatestWork.similarProjectsTitle}</p>
            <Box>
                {projectToShow.projectData.similarProjects.map((data, index) => (
                    <ProjectInfo data={data} key={index}/>
                ))}
            </Box>
        </SimilarProjectsWrapper >
    );
}

export default SimilarProjects;