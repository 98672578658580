import React from 'react';
import styled from 'styled-components';
import { Headline74, Text24Normal } from '../FontStyle';
import { Swiper, Slide } from 'react-dynamic-swiper'
import 'react-dynamic-swiper/lib/styles.css'
// images
import next from '../../img/next.png'
import prev from '../../img/prev.png'
import SliderTestimonials from './SliderTestimonials';
import TouchIcon from '../TouchIcon';

const PeopleSayWrapper = styled.div`
    margin-left:10%;
    padding:80px 0px 80px;
    position:relative;
    box-sizing: border-box;
    .title{
        box-sizing: border-box;
        margin-bottom:17px;
        margin-top:0px;
        white-space:pre-line;
    }
    .text{
        width:50%;
        box-sizing: border-box;
        .profession{
            font-size:16px;
            margin:0;
            padding-top:4px;
        }
    }
    @media (max-width:425px){
        padding:0px 0px 0px 24px !important;
        .text{
            width:100%;
            padding-right: 24px !important;
        }
    }
    @media (max-width:768px){
        width:100%;
        margin-left:0%;
        padding:0px 0px 0px 40px;
        box-sizing: border-box;
        .text{
            width:100%;
            padding-right: 40px;
        }
    }
`
const Grid = styled.div`
  width: 100%;
  height: 100%;
  margin-top:80px; 
  .swiper-slide{
      height:auto;
   }
  .sliderTestimonials{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    color:#3A3A3A !important;
    border-top:2px solid #BFBFBF;
    border-bottom:2px solid #BFBFBF;
    padding:40px 0px;
    box-sizing: border-box;
    height: 100%;
   } 
  .swiper-slider {
    .swiper-slide.swiper-slide-next {
        transform: none;
    }
    .swiper-button-next{
        display:block;
        background-image:url(${next}) !important;
        background-size: 100% 100%;
        z-index: 0;
        width:32px;
        height:32px;
        outline:0;
        right:200px;
        left: auto;
        top:100%;
        bottom:0px !important;
      }
      .swiper-wrapper , .swiper-containers ,.swiper-container{
        position:initial !important;
      }
      .swiper-button-prev{
       display:block;
       background-image:url(${prev}) !important;
       background-size: 100% 100%;
       z-index: 0;
       width:32px;
       height:32px;
       outline:0;
       top:100%;
       right:250px;
       left: auto;
    }
  }
  .position{
    display:none;
   }
  @media (min-width:768px) and (max-width:1024px){
    .swiper-slider{
    .swiper-button-prev{
        right:150px;
    }
    .swiper-button-next{
        right:100px;
    }
   }
  }
  @media (max-width:768px){
    margin-top:30px; 
    .quote{
        font-size:14px !important;
    }
    .position{
        display:block;
        padding:0px 24px 24px 0px;
    }
    .swiper-slider {
        .swiper-button-prev,.swiper-button-next{
            display:none;
        }
  }
`
const GallerySlider = ({ testimonials, src }) => {
    return (
        <div className="swiper-slider">
            <Swiper
                swiperOptions={{
                    slidesPerView: 3.5,
                    grabCursor: true,
                    spaceBetween: 32,
                    breakpoints: {
                        425: {
                            slidesPerView: 1.2,
                            spaceBetween: 16,
                        },
                        1024: {
                            slidesPerView: 2.2,
                            spaceBetween: 16,
                        },
                        1440:{
                            slidesPerView: 2.7, 
                        }
                    }
                }}
                pagination={false}
                navigation={true}
            >
                {testimonials && testimonials.map((testimonial, i) => (
                    <Slide key={i}>
                        <SliderTestimonials testimonial={testimonial} src={src} />
                    </Slide>
                ))}
            </Swiper>
        </div >
    );
};
const PeopleSay = ({ title, body, testimonials, whereIsIt, src }) => {

    return (
        <PeopleSayWrapper>
            <Headline74 className="title">{title}</Headline74>
            <Text24Normal className="text">{body}</Text24Normal>
            <Grid>
                <div className="position">
                    <TouchIcon />
                </div>
                <GallerySlider testimonials={testimonials} src={src} />
            </Grid>
        </PeopleSayWrapper>
    );
}

export default PeopleSay;


