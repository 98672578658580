import React from 'react';
import styled from 'styled-components';
import usePopUp from '../Hooks/usePopUp';
import PopUp from '../PopUp/PopUp';
import SetLanguages from '../SetLanguages';
import { LogoMobile } from '../icons';
import { NavBarList } from './NavBar';
import HirePopUp from '../Form/HirePopUp';

const NavBarMobileWrapper = styled.div`
 display:none;
 @media (max-width:768px){
    display:block;
    .navMenu{
      display: flex ;
      justify-content: space-between;
      align-items: center;
    }
    svg{
      padding-left:24px;
    }
}
`
const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: ${({ popUp }) => popUp ? 'translateX(0%)' : 'translateX(100%)'};
  height: 100vh;
  width:100%;
  position: fixed;
  top: 0;
  right: 0;
  transition: transform 0.9s ease-in-out;
  z-index:10;
  .navBar{
    position:absolute;
    top:0;
    right:0;
    z-index: 13;
    background: #F8F8F8;
    height:100%;
    width:300px;
  }
  .box{
    position:absolute;
    top:0;
    right:0;
    z-index:14;
    background: #FFFFFF;
    border-bottom-left-radius:60px;
    box-sizing: border-box;
    padding: 118px 42px 0px 45px;
    width:100%;
    .languagesItems ,.color{
      font-size:18px;
    }
    .menuItems{
        font-size:18px;
        font-weight:bold;
        text-align: left;
        margin:18px 0px;
        img{
          margin-top:-20px;
        }
        &.b2b{
          display: grid;
          grid-template-columns: 1fr 0.5fr;
          color:#A8A8A8 !important;
        }
        a{
            color:#161616;
            text-decoration: none;
            transition: color 0.3s linear;
        }
        .active{
          color:#FBD132 ;
        }
   }
  }
  @media (max-width: 576px) {
    font-size: 1.5rem;
    text-align: center;
  }
`
const StyledBurger = styled.button`
  position: ${({ popUp }) => popUp ? 'fixed' : 'absolute'};
  right:24px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 1.5rem;
  height: 1.5rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 12;
  &:focus {
    outline: none;
  }
  
  div {
    width: 1.5rem;
    height: 0.25rem;
    background: ${({ popUp, fill }) => popUp ? '#000000' : fill};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ popUp }) => popUp ? 'rotate(45deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
      opacity: ${({ popUp }) => popUp ? '0' : '1'};
      transform: ${({ popUp }) => popUp ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${({ popUp }) => popUp ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`
const Burger = ({ popUp, handlePopUp, fill }) => {
  return (
    <StyledBurger popUp={popUp} onClick={handlePopUp} fill={fill}>
      <div />
      <div />
      <div />
    </StyledBurger>
  )
}
const Menu = ({ popUp, subDomain, handlePopUp }) => {
  return (
    <>
      <StyledMenu popUp={popUp}>
        <PopUp background="#FFFFFF" />
        <div className="navBar">
          <div className="box">
            <NavBarList subDomain={subDomain} handlePopUp={handlePopUp} />
            {/*<SetLanguages />*/}
          </div>
        </div>
      </StyledMenu>
    </>
  )
}

const NavBarMobile = ({ subDomain, fill }) => {
  const [popUp, handlePopUp] = usePopUp();
  const [popUpForm, handlePopUpForm] = usePopUp();

  return (
    <>
      {popUpForm && <HirePopUp handlePopUp={handlePopUpForm} subDomain="b2b" />}
      <NavBarMobileWrapper popUp={popUp}>
        <div className="navMenu">
          <LogoMobile fill={fill} />
          <Burger popUp={popUp} handlePopUp={handlePopUp} fill={fill} />
        </div>
        <Menu popUp={popUp} handlePopUp={handlePopUpForm} subDomain={subDomain} />
      </NavBarMobileWrapper>
    </>
  );
}

export default NavBarMobile;
