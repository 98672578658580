import React from 'react';
import styled from 'styled-components';
import SingleCardSelected from './SingleCardSelected.js';


const CardWrapper = styled.div`
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
    border-bottom:1px solid #B3B4B5;
    padding:100px 0px 200px 0px;
    @media (max-width:899px){
        grid-template-columns: 1fr;
        padding:70px 0px 100px 0px;
    }
    @media (min-width:900px) and (max-width:1440px){
        grid-template-columns: repeat(2, 1fr);
    }
`

const CardsSelected = ({ students,selectedLang }) => {

    return (
        <>
            <CardWrapper>
                {students && students.map((card, i) => (
                    <SingleCardSelected card={card} key={i} hired={card.hired} selectedLang={selectedLang}/>
                ))
                }
            </CardWrapper>

        </>
    )
}

export default CardsSelected;