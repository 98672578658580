import React from 'react';
import CountUp from 'react-countup';
import styled from 'styled-components';
import Data from '../../Data';
import { Text24Bold } from '../FontStyle';
import useOnScreen from '../Hooks/useOnScreen';


const NumbersWrapper = styled.div`
   width:80%;
   margin:0 auto;
   margin-bottom:140px;
   @media (max-width:768px){
    width:100%;
    margin: 60px 0px;
    box-sizing: border-box;
   }
   
`
const NumberBox = styled.div`
    display: flex;
    justify-content: space-between;
   .number{
       font-size:100px;
       font-weight:600;
       margin:0px;
   }
   .child{
    .textColor{
        font-weight:600;
        white-space: pre;
    }
    }
  .wrapper {
    justify-self: center;
  }
  @media (max-width:768px){
    height: inherit;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    .number{
        font-size:70px;
    }
    .child{
        .textColor{
            color:#A8A8A8;
            font-size:18px;
        }
    }
   }
`
const Numbers = () => {
    const [setRef, visible] = useOnScreen({ rootMargin: '0px', })
    return (
        <NumbersWrapper ref={setRef}>
            <NumberBox>
                {Data.numbers.map((number, index) => (
                    <div className="wrapper" key={index}>
                        <CountUp end={number.number} suffix="+" className="number" start={visible ? 1 : null}>
                            {({ countUpRef }) => (
                            <>
                                <span className="number" ref={countUpRef} />
                                <div className="child">
                                    <Text24Bold className="textColor">{number.text}</Text24Bold>
                                </div>
                            </>
                            )}
                        </CountUp>
                    </div>
                ))}
            </NumberBox>
        </NumbersWrapper>);
}

export default Numbers;