import { useState } from 'react';

const useFilter = (list) => {
    const [filtered, setFiltered] = useState(list);
    const [clickedElement, setClickedElement] = useState(0)
    const [color, setColor] = useState("#FBD132")

    const filterCard = (id, color) => {

        if (id === 0) {
            setFiltered(list)
            setClickedElement(id)
            setColor(color)
        } else {
            if (clickedElement === id) {
                setFiltered(list)
                setClickedElement(0)
            } else {
                let filter = list.filter((element) => (element.academyId === id))
                setFiltered(filter)
                setClickedElement(id)
                setColor(color)
            }
        }
    }
    return [filterCard, filtered, clickedElement, color]

}
export default useFilter;