import React from 'react';
import styled from 'styled-components';
import { Headline50 } from '../FontStyle';
import ReadMore from './ReadMore';

const HowItWorkWrapper = styled.div`
    .position{
        position: relative;
        &:nth-child(2) {
            margin-bottom:80px;
        }
        .shapeOne{
            position:absolute;
            bottom:0;
            right:0px;
        }
        .shapeSecond{
            position:absolute;
            bottom:0px;
            left:0;
        }
    }
    .shapeMobile{
        display:none;
    }
    @media (max-width:768px){
        margin-top:40px;
        .shapeOne,.shapeSecond{
            display:none;
        }
        .position{
            &:nth-child(2) {
            margin-bottom:0px;
            }
        }
    }
    @media (max-width:425px){
        margin-bottom:40px;
       .shapeMobile{
           display:block;
           width:100%;
           height:100%;
           padding:0px 24px;
           box-sizing:border-box;
        }
    }
    @media (max-width:1200px){
        .shapeSecond{
           right:0;
           left:auto !important;
        }
    }
`

const HowItWorkWrapperText = styled.div`
    width:80%;
    margin:0 auto;
    padding:40px 0px 80px 0px;
    box-sizing:border-box;
    .grid{
        display:grid;
        grid-template-columns: 0.03fr 1fr;
        grid-gap:20px;
        padding-left:190px;
        padding-top:60px;
    }
    .text{
       white-space:pre-line;
       line-height: 34px;
       a{
           color:#3A3A3A;
       }
    }
    .arrowImg{
        padding-top:3px;
    }
    @media (max-width:480px){
       .block{
          padding-bottom:0px;
       }
        &:nth-child(3) {
           padding-bottom:24px;
        }
    }
    @media (max-width:425px){
        padding:0px 24px 40px !important;
    }
    @media (max-width:768px){
       padding:0px 40px 40px;
       width:100%;
      .title{
        font-size:25px;
      }
      .text{
        font-size:16px;
       }
    }
    @media (max-width:1200px){
        .arrowImg{
            display:none;
        }
        .grid{
            grid-template-columns:1fr;
            padding:0px;
            padding-top:30px;
        }
        .arrowMobile{
            &:before{
                content:"";
                background:url(${props => props.arrowMobile});
                background-repeat: no-repeat;
                padding:5px 20px;
            }
        }
    } 
  }
`
const HowItWork = ({ data, arrow, shapeOne, shapeSecond, arrowMobile, shapeMobile }) => {

    return (
        <HowItWorkWrapper>
            {data.map((block, index) => (
                <div className="position" key={index}>
                    {index === 0 ? <img src={shapeOne} className="shapeOne" alt="shape" />
                        : <img src={shapeSecond} className="shapeSecond" alt="shape" />
                    }
                    <HowItWorkWrapperText key={index} arrowMobile={arrowMobile}>
                        <Headline50 className="title">{block.title}</Headline50>
                        <div className="grid">
                            <img src={arrow} alt="" className="arrowImg" />
                            <ReadMore text={block.text} textUnder={block.textUnder} />
                        </div>
                    </HowItWorkWrapperText>
                </div>
            ))}
            <img src={shapeMobile} className="shapeMobile" alt="shape" />
        </HowItWorkWrapper>
    );
}

export default HowItWork;