import React from 'react';
import styled from 'styled-components';
import Data from '../../Data';
import CardBox from '../CardBox';
import DropDownMobileFilter from '../Filter/DropDownMobileFilter';
import { FilterMenu } from '../Filter/FilterMenu';
import { Headline50 } from '../FontStyle';
import HirePopUp from '../Form/HirePopUp';
import useCounter from '../Hooks/useCounter';
import useFilter from '../Hooks/useFilter';
import usePopUp from '../Hooks/usePopUp';
import Slider from '../Slider';


const ProgramsWrapper = styled.div`
   width:80%;
   margin:0 auto;
   @media (max-width:768px){
    width:100%;
    box-sizing: border-box;
    p{
        padding:0px 24px;
    }
   }
`
const Desktop = styled.div`
   padding:60px 0px;
   ul{
       margin:0px;
       list-style:none;
       display:flex;
       padding:0;
       .active{
          color:#161616;
       }
       li{
        padding:0px 30px 0px 0px;
        font-size:25px;
        font-weight:bold;
        color:#A8A8A8;
        cursor:pointer;
        border-bottom:none;
        &:first-child{
            padding-left:0px; 
            position:relative;
        }
        &:first-child:after{
           content:"|";
           color:#A8A8A8;
           padding-left:10px;
           position:absolute;
           top:-2px;
        }
       }
   }  
   @media (max-width:768px){
    ul{
        flex-direction: column;
    }
   }
  @media (max-width:768px){
    display:none;
   }
`
const Mobile = styled.div`
   display:none;
   @media (max-width:768px){
      display:block;
   }
`

const Programs = ({ subDomain }) => {
    const [popUp, handlePopUp] = usePopUp();
    const [filterCard, filtered, clickedElement] = useFilter(Data.programs.cards)
    const [resetCounter] = useCounter(filtered, 6);

    let customProps = {
        subDomain: subDomain,
        filterMenuList: Data.programs.listCardsFilter,
        clickedElement: clickedElement,
        handleClick: filterCard,
        resetCounter: resetCounter,
    }
    return (
        <>
            {popUp && <HirePopUp handlePopUp={handlePopUp} subDomain={subDomain} />}
            <ProgramsWrapper>
                <Headline50>{Data.programs.title}</Headline50>
                <Desktop>
                    <FilterMenu
                        {...customProps}
                    />
                    <CardBox cardsToShow={filtered} border="#BFBFBF" hoverBorder="#183FF3" handlePopUp={handlePopUp} />
                </Desktop>
                <Mobile>
                    <DropDownMobileFilter
                        {...customProps} />
                    <Slider handlePopUp={handlePopUp} slideImages={filtered} hoverBorder="#FBD132" whereIsIt="projects" loop={false} />
                </Mobile>
            </ProgramsWrapper>
        </>
    );
}

export default Programs;