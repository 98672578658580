import React from 'react';
import styled from 'styled-components';
import Data from '../../Data';
import Slider from '../Slider';
import TouchIcon from '../TouchIcon';

const SliderGalleryWrapper = styled.div`
  width:100%;
  margin:0 auto;
  height: 100%;
  .width{
    width:80%;
    margin:0 auto;
  }
  .flex{
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom:24px; 
  }
  .title{
    font-size:24px;
    font-weight:bold;
    color:#161616;
    margin:0;
  }
  .sliderImage{
    img{
        width: 100%;
        height: 100%;
      }
  } 
  @media (max-width:425px){
    .flex{
      display:block;
    }
  }
`
const Grid = styled.div`
  width: 100%;
  height: 100%;
  .swiper-slider {
    width: 100%;
    overflow: hidden;
  }
  img{
    width:100%;
    height:100%;
  }
`
const SliderGallery = ({ projectToShow }) => {
  return (
    <SliderGalleryWrapper>
      <div className="flex width">
        <p className="title">{Data.ourLatestWork.gallerySliderTitle}</p>
        <TouchIcon />
      </div>
      <Grid>
        <Slider
          slideImages={projectToShow.projectData.galleryImage}
          whereIsIt="SliderGallery"
          centeredSlides={false}
          projectToShow={projectToShow}
        />
      </Grid>
    </SliderGalleryWrapper >
  );
}

export default SliderGallery;

