import React, { useContext } from 'react';
import styled from 'styled-components';
import usePopUp from '../Hooks/usePopUp';
import CardDetails from './CardDetails';
import Data from '../../Data';
import arrow from '../../img/Filter/arrow-right.png'
import { Context } from '../../Context/context';

const SingleCardWrapper = styled.div`
   width:100%;
   border-bottom:1px solid #B3B4B5;
   padding:60px 0px 40px 0px;
   &:first-child {
    padding-top:25px;
   }
   @media (max-width:500px){
    padding:40px 0px 20px 0px;
   }
`
const StudentInfo = styled.div`
   display:flex;
   justify-content: space-between;
`
const StudentTools = styled.div`
   padding-top:20px;
   p{
    color:#3A3A3A;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;  
   }
   button{
    font-style: normal;
    font-weight: normal;
    font-size: 14px; 
    border:1px solid #CBCBCB;
    background:transparent;
    padding:5px 30px;
    margin-right:20px;
    margin-bottom:15px;
    font-family: 'Montserrat';
    :focus{
        outline:none
    }
   }
   @media (max-width:500px){
    padding-top:0px;
    button{  
        font-size: 12px; 
        margin-right:2px;
        margin-bottom:4px;
        padding:5px 25px;
        }
     p {
    font-size: 12px;  
     }
    }
    @media (min-width:500px) and (max-width:1024px){
    padding-top:0px;
    button{  
        font-size: 16px; 
        }
        p {
        font-size: 14x;  
        }
    }
`
const Body = styled.div`
   width:80%;
   margin-left: 30px;
   @media (max-width :768px){
    margin-left: 4px;
   }
`
const Content = styled.div`
   display:flex;
   justify-content:space-between;
   width: 100%;
   .imgWrapper{
       width:131px;
       height:131px;
       img{
           width:100%;
           height:100%;
           border-radius:50%;
           object-fit: cover;
       }
    }
    @media (max-width :500px){
        .imgWrapper{
            width:51px;
            height:51px;
        }
    }
    @media (min-width:500px) and (max-width:1024px){
        .imgWrapper{
            width:80px;
            height:80px;
        }
    }
    @media (min-width:1025px) and (max-width:1440px){
        .imgWrapper{
            width:110px;
            height:110px;
        }
    }
`
const StudentCredentials = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap:2px;
   .arrowRight{
     justify-self: flex-end;
   }
   .name{
    color:#3A3A3A;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    margin:0px;
   }
   .profession{
    color:#3A3A3A;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    margin:5px 0px;
   }
   @media (max-width:500px){
    grid-template-columns: 3fr 1fr;
    .name{
        font-size: 16px;
        }
    .profession{
        font-size: 14px;
        margin:0px;
    }
   }
   @media (min-width:500px) and (max-width:1440px){
    .name{
        font-size: 22px;
        }
    .profession{
        font-size: 18px;
    }
   }
`
const CheckProfile = styled.p`
    color:#3A3A3A;
    font-size:24px;
    cursor:pointer;
    margin:0px;
    &:hover{
       color:${props => props.color};
    }
    @media (max-width :500px){
        display:none;
    }
    @media (min-width:500px) and (max-width:1440px){
        font-size: 18px;
    }
`
const Image = styled.img`
   display:none;
   @media (max-width :500px){
    display:block;
    }
`
const SingleCard = ({ card, color, props }) => {
    const [popUp, handlePopUp] = usePopUp()
    const {selectedLang} = useContext(Context)
    return (
        <>
            { popUp ? <CardDetails handlePopUp={handlePopUp} card={card} selectedLang={selectedLang}/>
                :
                <SingleCardWrapper>
                    <StudentInfo>
                        <Content>
                            <div className="imgWrapper">
                                <img src={`https://academy.brainster.co/storage/${card.profileImg}`} alt="student" />
                            </div>
                            <Body>
                                <StudentCredentials>
                                    <div>
                                        <p className="name">{selectedLang === "en" ? card.name_en : card.name}</p>
                                        <p className="profession">{Data.filterMenu.find((el,i)=>el.id===card.academyId).title}</p>
                                    </div>
                                    <div className="arrowRight">
                                        <Image src={arrow} alt="arrow" onClick={() => handlePopUp()} />
                                        <CheckProfile className="arrow" color={color} onClick={() => handlePopUp()}>{Data.singleCard.checkProfile}</CheckProfile>
                                    </div>
                                </StudentCredentials>
                                <StudentTools>
                                    <p>{Data.singleCard.tools}</p>
                                    {card.skills.TECH_SKILL && card.skills.TECH_SKILL.map((tool, i) => (
                                        <button key={i}>{tool}</button>
                                    ))
                                    }
                                </StudentTools>
                            </Body>
                        </Content>
                    </StudentInfo>
                </SingleCardWrapper >
            }
        </>
    );
}

export default SingleCard;