import React from 'react';
import styled from 'styled-components';
import Data from '../../Data';


import { FB, Instagram, Linkedin, Logo } from '../icons';

const FooterContactSection = styled.div`
    display: grid;
    grid-template-columns: 1fr 9fr ;
    padding:50px 0px 90px;
    a{
        svg{
        fill:${props => props.fill};
            &:hover{
                fill:${props => props.hover}
            }
        }
    }
    .contactInfo{
        align-self: center;
        display:flex;
        span{
            font-size:20px;
            margin-right: 10px;
        }
    }
    .left{
        display:grid;
        grid-template-columns: 4fr 1fr ;
    }
    .icons{
        a{
            margin-right: 15px;
            display:inline-block;
        }
        align-self:center;
        justify-self: end;
    }
    @media (max-width:425px){
        .contactInfo{
            span{
                font-size:14px;
            }
        }
        .icons{
            a{
                margin-right: 10px;
            }
        }
    }
    @media (max-width:1400px){
        .contactInfo{
           display:block;
           div{
            margin: 10px 0px;
           }
        }
    }
    @media (max-width:1024px){
        padding:40px 0px 60px;
        grid-template-columns: 1fr;
        .left{
            margin-top:20px;
            grid-template-columns: 1fr 1fr ;
        }
        .contactInfo{
            align-self:end;
            span{
                display:block;
            }
        }
        .icons{
            align-self:end;
        }
    }
   
`
const FooterContact = ({ fill, hover }) => {
    return (
        <FooterContactSection fill={fill} hover={hover}>
            <a href={Data.footer.brainsterLink} className="image">
                <Logo  />
            </a>
            <div className="left">
                <div className="contactInfo">
                    <span color={fill}>{Data.footer.contactText}</span>
                    {Data.footer.contacts.map((contact, i) => (
                        <div key={i}>
                            <span color={fill}>{contact.name}</span>
                            <span color={fill}>{contact.email}</span>
                        </div>
                    ))}
                </div>
                <div className="icons">
                    <a href={Data.footer.instagramLink}><Instagram /></a>
                    <a href={Data.footer.fbLink}><FB /></a>
                    <a href={Data.footer.linkedinLink}><Linkedin /></a>
                </div>
            </div>
        </FooterContactSection >
    );
}

export default FooterContact;
