import React from 'react';
import styled from 'styled-components';

const List = styled.li`
    cursor:pointer;
    color:#3A3A3A;
    cursor:pointer;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    border-bottom:1px solid #B3B4B5;
    list-style: none;
    padding:25px 0px;
    font-family:"Montserrat";
    &.hiring {
      font-weight: bold;
      padding:25px 0px;
      animation: changeColor 0.5s ease-in-out;
      ::before {
        display:inline;
        padding-left:20px;
        padding-right: 10px;
        color:${props => props.color && props.color};
      }
    }
    @media (max-width:768px){
        font-size: 14px;
        &.hiring {
            margin-left: -20px;
            ::before {
                padding-left:0px;
            }
        }
    }
    @media (min-width:768px) and (max-width:1440px){
        font-size: 18px;
     }
    
    &.b2b{
        color: #161616;
    }
    @keyframes changeColor {
            0%   {font-weight:normal;}
            70%  {font-weight:400;}
            100% {font-weight:600;}
    }
      
`
export const FilterMenu = ({ filterMenuList, subDomain, clickedElement, handleClick, resetCounter, handlePopUp }) => {

    return (
        <ul>
            {filterMenuList && filterMenuList.map((element, index) => (
                <List
                    key={index}
                    className={clickedElement === element.id && `${subDomain} arrowBefore`}
                    color={element.color}
                    onClick={() => { handleClick(element.id, element.color); resetCounter() }}>
                    {element.filterName}
                </List>
            ))}
        </ul>
    )
}