import React from 'react';
import styled from 'styled-components';

import Data from '../../Data';
import { Text34Bold } from '../FontStyle';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PartnersWrapper = styled.div`
    width:80%;
    margin:0 auto;
    font-family:Montserrat;
    padding:0px 25px 100px 25px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    @media (max-width:425px){
        width:100%;
        padding:60px 24px 24px !important;
    }
    @media (max-width:768px){
        width:100%;
        padding:60px 40px 40px;
    }
`
const ImageBlock = styled.div`
    //  width:80%;
    //  max-width: 300px;
     display: flex;
     flex-wrap: wrap-reverse;
     align-items: baseline;
     justify-content: flex-end; 
     @media (max-width:768px){
       width: 100%;
       flex-direction: row;
       justify-content: center;
       
     }

     img {
         max-width: 100%;
     }
`
const Partners = ({ partners }) => {

    const settings = {
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 4,
        slidesToScroll: 1,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 1500,
        dots: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    autoplaySpeed: 1500,
                    centerMode: true,
                }
            }
        ]
    };


    return (
        <PartnersWrapper>
            <Text34Bold>{Data.partners.title}</Text34Bold>
            <Slider {...settings}>
                {partners.companies && partners.companies.map((partner, index) =>
                    <ImageBlock key={index}>
                        <img src={`https://academy.brainster.co/storage/${partner.logo}`} alt="student" />
                    </ImageBlock >
                )}
            </Slider>
        </PartnersWrapper>
    );
}

export default Partners;