import React, { useContext } from 'react';
import styled from 'styled-components';
import { Context } from '../../Context/context';
import Data from '../../Data';
import { Text20Bold, Text20Normal } from '../FontStyle';

const Bottom = styled.div`
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    padding-top:30px;
    align-items: center;
    @media (max-width:768px){
        grid-template-columns: 1fr;
    }
`
const ImageWrapper = styled.div`
    display:flex;
    .profileImg , .logoImgWrapper {
        width:80px;
        height:80px;
    }
    .profileImg img{
      border-radius: 50%;
      display: block;
      object-fit: cover; 
    }
    .logoImgWrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        background:#F9F9F9;
        margin-left: -15px;
        border-radius: 50%;
        border-left:5px solid #FFFFFF;
        width:80px;
        height:80px;
        .logoImg{
            width:60px;
            height:60px;
            // display: flex;
            // justify-content: center;
            // align-items: center;
        }
        img{
            width:100%;
            height:100%;
            object-fit: contain; 
        }
    }
    .profileImg img{
        width:100%;
        height:100%;
    }
    @media (max-width:768px){
       padding-bottom:15px;
    }

`
const SliderTestimonials = ({ testimonial, src }) => {
    const { selectedLang } = useContext(Context)
    return (
        <div className="sliderTestimonials">
            <Text20Normal className="quote">{selectedLang === "en" ? testimonial.employmentDetails.quote_en : testimonial.employmentDetails.quote}</Text20Normal>
            <Bottom>
                <ImageWrapper>
                    <div className="profileImg">
                        <img src={`${src}/${testimonial.profileImg}`} alt="" />
                    </div>
                    <div className="logoImgWrapper">
                        <div className="logoImg">
                            <img className="personImage" src={`${src}/${testimonial.employmentDetails.company.logo}`} alt="" />
                        </div>
                    </div>
                </ImageWrapper>
                <div className="text">
                    <Text20Bold>{selectedLang === "en" ? testimonial.name_en : testimonial.name}</Text20Bold>
                    <p className="profession">{Data.filterMenu.find((el, i) => el.id === testimonial.academyId).title}</p>
                </div>
            </Bottom>
        </div>
    );
}

export default SliderTestimonials;