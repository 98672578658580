import React from 'react';
import styled from 'styled-components';
import Data from '../../Data';
import { Headline50, Text24Normal } from '../FontStyle';
import roundImage from '../../img/B2B/MoreInfo/roundImage.png'
import arrows from '../../img/B2B/MoreInfo/arrows.jpg'
import usePopUp from '../Hooks/usePopUp';
import HirePopUp from '../Form/HirePopUp';
import mobile from '../../img/B2B/MoreInfo/mobile.jpg'
import mobileArrows from '../../img/B2B/MoreInfo/arrowsMobile.png'

const MoreInfoWrapper = styled.div`
    position:relative;
    display: grid;
    grid-template-columns: 2fr 1.5fr;
    grid-gap:75px;
    .mobile ,.mobileArrow{
        display:none;
    }
    .image{
        justify-self: end;
        img{
            width: 100%;
            height: 100%;
        }
    }
    @media (max-width:768px){
        width:100%;
        grid-template-columns:1fr;
        grid-gap:0px;
        padding-top:40px;
        .desktop{
            display:none;
        }
        .mobile{
            display:block;
        }
        .mobileArrow{
            display:block;
            position:absolute;
            right:0;
            top:10px;
        }
    
    }
`
const Text = styled.div`
    display: flex;
    justify-content: center;
    align-items: baseline;
    align-self:center;
    img{
        padding:0px 32px;
    }
    .text{
        div{
            padding-left:100px;
            padding-top:32px;
            p{
                padding-bottom:40px;
            }
        }
    }
    @media (max-width:768px){
        flex-direction: column;
        padding:0px 24px;
        box-sizing: border-box;
        .text{
            div{
                padding:24px 0px 40px 0px;
            }
        }
    }
`

const MoreInfo = ({ subDomain }) => {
    const [popUp, handlePopUp] = usePopUp();
    return (
        <>
            {popUp && <HirePopUp handlePopUp={handlePopUp} subDomain={subDomain} />}
            <MoreInfoWrapper>
                <Text>
                    <img src={arrows} alt="" className="desktop" />
                    <img src={mobileArrows} alt="" className="mobileArrow" />
                    <div className="text">
                        <Headline50>{Data.moreInfo.title}</Headline50>
                        <div>
                            <Text24Normal>{Data.moreInfo.text}</Text24Normal>
                            <button className={`button ${subDomain}`} onClick={() => handlePopUp()}>{Data.moreInfo.button}</button>
                        </div>
                    </div>
                </Text>
                <div className="image">
                    <img src={roundImage} className="desktop" alt="" />
                    <img src={mobile} className="mobile" alt="" />
                </div>
            </MoreInfoWrapper>
        </>
    );
}

export default MoreInfo;