import { useEffect, useState } from 'react';


const useCounter = (list, number) => {
    const [arr, setArr] = useState([])
    const [count, setCount] = useState(number)
    const [hideButton, setHideButton] = useState(false)

    useEffect(() => {
        let cardlist = [];
        list && list.map((el, i) => {
            if (i < count) {
                cardlist.push(el)
            }
            return cardlist
        })
        setArr(cardlist)

        if (list.length <= number) {
            setHideButton(true)
        } else {
            if (count >= list.length) {
                setHideButton(true)
            } else {
                setHideButton(false)
            }
        }
    }, [list, count, number])

    const onCount = () => (
        setCount(count + 2)
    )
    const resetCounter = () => (
        setCount(number)
    )

    return [arr, onCount, hideButton, resetCounter];
}

export default useCounter;