import React from 'react';
import styled from 'styled-components';
import Data from '../../Data';
import { Headline50, Text20Normal } from '../FontStyle';

const HowWillHelpUsWrapper = styled.div`
   width:80%;
   margin:0 auto;
   padding:40px 0px 140px;
   .heading{
      width:55%;
      margin-bottom:80px;
   }
   @media (max-width:768px){
    width:100%;
    padding:0px 24px;
    box-sizing: border-box;
    .heading{
       width:100%;
       margin-bottom:40px;
   }
  }
`
const Boxes = styled.div`
   display: grid;
   grid-template-columns: 1fr 1fr;
   grid-column-gap:158px;
   grid-row-gap: 40px;
   padding-left:128px;
   @media (max-width:768px){
    padding-left:0px;
    grid-template-columns: 1fr;
    grid-row-gap:24px;
    .body{
        font-size:16px;
        line-height: 28px;
    }
   }
`
const Title = styled.p`
    font-size:35px;
    font-weight:bold;
    color:#161616;
    &:before{
     content:"";
     background:url(${props => props.source}) no-repeat;
     padding-right: 50px;
    }
    @media (max-width:768px){
        font-size:20px;
        &:before{
            background-size: 20px 20px;
            padding-right: 30px;
        }
    }
`

const HowWillHelpUs = () => {
    return (
        <HowWillHelpUsWrapper>
            <Headline50 className="heading">{Data.howWillHelpUs.title}</Headline50>
            <Boxes>
                {Data.howWillHelpUs.boxes.map((box, index) => (
                    <div key={index}>
                        <Title source={process.env.PUBLIC_URL + `img/B2B/HowWillHelpUs/${box.id}.png`}>{box.title}</Title>
                        <Text20Normal className="body">{box.body}</Text20Normal>
                    </div>
                ))}
            </Boxes>
        </HowWillHelpUsWrapper>
    );
}

export default HowWillHelpUs;