import React, { useContext } from 'react';
import styled from 'styled-components';
import { Headline74 } from '../FontStyle';
import { Text20Normal } from '../FontStyle'
import roundImage from '../../img/B2B/ourApprouch/roundImage.jpg'
import { Context } from '../../Context/context';
import mobileB2b from '../../img/B2B/ourApprouch/mobile.jpg'

const HowItWorksWrapper = styled.div`
   width:80%;
   margin:0 auto;
   padding:0px 0px 100px;
   @media (max-width:768px){
       padding:6px 0px 30px;
       width:100%;
       padding:0px 40px 40px 40px;
       box-sizing: border-box;
   }
   @media (max-width:425px){
    padding:0px 24px 40px 24px !important;
   }
`
const HowItWorksBlock = styled.div`
   display:grid;
   grid-template-columns: 1fr 1fr;
   grid-gap:179px;
   .video{
       width:100%;
       heigh:100%;
    }
    .titleMobile{
        display:none;
    }
    .ourApprouchB2b{
        display: flex;
        align-items:flex-end;
        .roundImageB2B{
            position: relative;
            left: -30%;
            .mobile{
                display:none;
            }
           img{
               width:100%;
               height:100%;
           }
        }
    }
   @media (max-width:1024px){
    grid-template-columns: 1fr;
    grid-gap:0px;
    .titleMobile{
        display:block;
    }
    .ourApprouchB2b{
        order:2;
        .roundImageB2B{
            left: -24px;
            right:-24px;
        .desktop{
            display:none;
           }
        .mobile{
            display:block;
        }
        }
    }
   }
`
const Text = styled.div`
   display:grid;
   grid-template-columns: 0.03fr 1fr;
   grid-gap:20px;
   .text{
       padding-bottom:40px;
    }
    div{
        font-size:16px !important;
        line-height:28px;
    }
    @media (max-width:768px){
        grid-template-columns:1fr;
       .icons{
           display:none;
       }
       .text{
        padding-bottom:10px;  
       }
       .text,.textUnder{
           font-size:16px;
           line-height:28px;
       }
       .text{
        &:before{
            content:"";
            background:url(${props => props.icon});
            background-repeat: no-repeat;
            padding:5px 20px;
        }
     }
    }

`
const ImageMobileB2B=styled.div`
  display:none;
  @media (max-width:1024px){
      display:block;
      padding:0px 0px 40px;
      img{
          width:100%;
          height:100%;
          object-fit: contain;
      }
  }
`
const HowItWorks = ({ whereIsIt, title, textRight, textLeft, textRightUnder, textLeftUnder, iconRight, iconLeft, smallTitle, challengeMobile, solutionMobile }) => {
    const { isMobile } = useContext(Context)
    return (
        <>
            <HowItWorksWrapper>
                {whereIsIt !== "innovation" && <Headline74 className={`title ${whereIsIt}`}>{title}</Headline74>}
                {whereIsIt === "innovation" && <Headline74 className="titleMobile">{isMobile ? challengeMobile : title}</Headline74>}
                <HowItWorksBlock>
                    {whereIsIt === "innovationOurApproach" ?
                        <div></div> :
                        whereIsIt === "innovation" ?
                            <Text icon={iconLeft}>
                                <img src={iconLeft} alt="" className="icons" />
                                <div>
                                    <Text20Normal className="text">{textLeft}</Text20Normal>
                                    <Text20Normal className="textUnder">{textLeftUnder}</Text20Normal>
                                </div>
                            </Text>
                            :
                            <div className="ourApprouchB2b">
                                {/* <Headline50>{smallTitle}</Headline50> */}
                                <div className="roundImageB2B">
                                    <img src={roundImage} className="desktop" alt="" />
                                    {/* <img src={mobileB2b} className="mbile" alt="" /> */}
                                </div>
                            </div>
                    }
                    {whereIsIt === "innovation" && <Headline74 className="titleMobile">{solutionMobile}</Headline74>}
                    <Text icon={iconRight}>
                        <img src={iconRight} alt="" className="icons" />
                        <div>
                            <Text20Normal className="text">{textRight}</Text20Normal>
                            <Text20Normal className="textUnder">{textRightUnder}</Text20Normal>
                        </div>
                    </Text>
                </HowItWorksBlock>
            </HowItWorksWrapper>
            {whereIsIt === 'b2b' &&
                <ImageMobileB2B>
                    <img src={mobileB2b} className="mobile" alt="" />
                </ImageMobileB2B>
            }
        </>
    );
}

export default HowItWorks;

