import React from 'react';
import styled from 'styled-components';
import usePopUp from '../Hooks/usePopUp';
import Data from '../../Data';
import arrow from '../../img/Filter/arrow-right.png'
import CardDetails from '../Cards/CardDetails';
import { Text18Normal, Text24Bold } from '../FontStyle';


import linkedin from '../../img/Icons/linkedin.svg';
import website from '../../img/Icons/website.svg';

// cooding
import github from '../../img/Icons/github.svg';

// disign
import dribble from '../../img/Icons/dribble.svg';
import behance from '../../img/Icons/behance.svg';

//social
import twitter from '../../img/Icons/twitter.svg';
import facebook from '../../img/Icons/facebook.svg';
import upwork from '../../img/Icons/upwork.svg';


const SingleCardWrapper = styled.div`
   width:100%;
   background-color:#FFFFFF;
   height:100%;
   box-shadow: 0px 3px 6px rgba(0,0,0,0.07);
   position:relative;
   .hiredMessage{
       position:absolute;
       top:0;
       right:0;
       left:0;
       background: #FBD132;
       height: 33px;
       display: flex;
       justify-content: center;
       align-items: center;
       p{
           margin:0px;
           font-size:18px;
           font-weight:bold;
           color:#3A3A3A;
       }
    @media (max-width:767px){
       p{
        font-size:14px;  
       }
    }  
   }
`
const ContentWrapper = styled.div`
   padding:40px 30px;
   height: inherit;
   box-sizing: border-box;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   @media (max-width:325px){
     padding:10px;
   }
   @media (max-width:768px){
    padding:30px 20px;
  }
`
const StudentTools = styled.div`
   padding-top:20px;
   p{
    color:#3A3A3A;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;  
   }
   button{
    font-style: normal;
    font-weight: normal;
    font-size: 12px; 
    border:1px solid #CBCBCB;
    background:transparent;
    padding:5px 30px;
    margin-right:9px;
    margin-bottom:9px;
    font-family: 'Montserrat';
    :focus{
        outline:none
    }
   }
   @media (max-width:425px){
       p{
        font-size: 12px; 
        }
        button{
            margin-right: 2px;
            margin-bottom: 4px;
            padding: 5px 25px;
        }
   }
`
const CardHeader = styled.div`
    display: grid;
    grid-template-columns: 0.5fr 2fr;
    .imgWrapper{
        width:80px;
        height:80px;
        margin-right:20px;
        img{
            width:100%;
            height:100%;
            border-radius:50%;
            object-fit: cover;
        }
     }
`
const StudentCredentials = styled.div`
   display:flex;
   justify-content:space-between;
   align-items: center;
   .profession{
    color:#3A3A3A;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    margin:5px 0px;
   }
   @media (max-width:425px){
    .name{
        font-size: 16px;
        }
    .profession{
        font-size: 14px;
        margin:0px;
    }
   }
   @media (min-width:426px) and (max-width:1024px){
    .name{
        font-size: 20px;
        }
    .profession{
        font-size: 18px;
    }
   }
`
const CheckProfile = styled.p`
    color:#3A3A3A;
    font-size:18px;
    cursor:pointer;
    margin:0px;
    &:hover{
       color:${props => props.color};
    }
    @media (max-width :425px){
        display:none;
    }
    @media (min-width:426px) and (max-width:1440px){
        font-size: 18px;
    }
`
const Image = styled.img`
   display:none;
   @media (max-width :425px){
    display:block;
    }
`
const Bottom = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 20px;
`
const Icons = styled.div`
   display:flex;
   align-items:baseline;
   a{
    color: #3A3A3A;
       p{ 
         font-size:14px;
         margin:0;
        }
    margin-right: 10px; 
    }
  .icon{
    width:22px;
    height:18px;
    img{
        width:100%;
        height:100%;
    }
   }

`
const SingleCardSelected = ({ card, hired, selectedLang }) => {
    const [popUp, handlePopUp] = usePopUp()

    return (
        <>
            {popUp ? <CardDetails handlePopUp={handlePopUp} card={card} selectedLang={selectedLang} />
                :
                <SingleCardWrapper>
                    {hired &&
                        <div className="hiredMessage">
                            <p>{Data.singleCard.hiredMessage}</p>
                        </div>
                    }
                    <ContentWrapper>
                        <div>
                            <CardHeader>
                                <div className="imgWrapper">
                                    <img src={`https://academy.brainster.co/storage/${card.profileImg}`} alt="student" />
                                </div>
                                <StudentCredentials>
                                    <div>
                                        <Text24Bold>{selectedLang === "en" ? card.name_en : card.name}</Text24Bold>
                                        <Text18Normal>{Data.filterMenu.find((el, i) => el.id === card.academyId).title}</Text18Normal>
                                    </div>
                                </StudentCredentials>
                            </CardHeader>
                            <StudentTools>
                                <p>{Data.singleCard.tools}</p>
                                {card.skills.TECH_SKILL && card.skills.TECH_SKILL.map((tool, i) => (
                                    <button key={i}>{tool}</button>
                                ))
                                }
                            </StudentTools>
                        </div>
                        <Bottom>
                            <Icons>
                                {card.cv && <a href={`https://academy.brainster.co/storage/${card.cv}`} target="_blank" rel="noreferrer" ><p>{Data.selectedCandidates.checkCv}</p></a>}
                                {card.socialProfiles && card.socialProfiles.linkedin && <a className="icon" href={card.socialProfiles.linkedin} target="_blank" rel="noreferrer" ><img src={linkedin} alt="linkedin" /></a>}
                                {card.socialProfiles && card.socialProfiles.website && <a className="icon" href={card.socialProfiles.website} target="_blank" rel="noreferrer" ><img src={website} alt="website" /></a>}
                                {card.socialProfiles && card.socialProfiles.behance && <a className="icon" href={card.socialProfiles.behance} target="_blank" rel="noreferrer" ><img src={behance} alt="behance" /></a>}
                                {card.socialProfiles && card.socialProfiles.dribble && <a className="icon" href={card.socialProfiles.dribble} target="_blank" rel="noreferrer" ><img src={dribble} alt="dribble" /></a>}
                                {card.socialProfiles && card.socialProfiles.github && <a className="icon" href={card.socialProfiles.github} target="_blank" rel="noreferrer" ><img src={github} alt="github" /></a>}
                                {card.socialProfiles && card.socialProfiles.twitter && <a className="icon" href={card.socialProfiles.twitter} target="_blank" rel="noreferrer" ><img src={twitter} alt="twitter" /></a>}
                                {card.socialProfiles && card.socialProfiles.facebook && <a className="icon" href={card.socialProfiles.facebook} target="_blank" rel="noreferrer" ><img src={facebook} alt="facebook" /></a>}
                                {card.socialProfiles && card.socialProfiles.upwork && <a className="icon" href={card.socialProfiles.upwork} target="_blank" rel="noreferrer" ><img src={upwork} alt="upwork" /></a>}
                            </Icons>
                            <Image src={arrow} alt="arrow" onClick={() => handlePopUp()} />
                            <CheckProfile className="arrowCard" onClick={() => handlePopUp()}>{Data.singleCard.checkProfile}</CheckProfile>
                        </Bottom>
                    </ContentWrapper>
                </SingleCardWrapper >
            }
        </>
    );
}

export default SingleCardSelected;