import React, { useContext } from 'react';
import FAQBlock from '../hiring/FAQ/FAQBlock';
import Filter from '../Filter/Filter';
import Footer from '../Footer/Footer';
import Header from '../Header';
import useFetch from '../Hooks/useFetch';
import Partners from '../hiring/Partners';
import { API } from '../../Consts/Api';
import Data from '../../Data';
import { Context } from '../../Context/context';
// images
import headerBackground from '../../img/Header/HeaderImg.jpg';
import headerBackgroundMobile from '../../img/Header/backgroundMobile.jpg';
import PeopleSay from '../SliderTestimonials/PeopleSay';

const HomeHiring = ({ children, subDomain }) => {
    const studentsList = useFetch(API.routes.getPromotedStudents);
    const imagesPartners = useFetch(API.routes.getPartners);
    const hiredStudents = useFetch(API.routes.getHiredStudents);
    const { selectedLang } = useContext(Context)
    return (
        <>
            {studentsList && imagesPartners && hiredStudents &&
                <React.Fragment>
                    {children}
                    <Header
                        subDomain={subDomain}
                        title={Data.header.title}
                        body={Data.header.body}
                        button={Data.header.button}
                        background={headerBackground}
                        backgroundMobile={headerBackgroundMobile}
                        fill="#F8F8F8"
                        selectedLang={selectedLang}
                    />
                    {/* <Clients subDomain={subDomain} title={Data.partners.title} logos={logos} /> */}
                    {/* <Partners imagesPartners={imagesPartners.data} /> */}
                    <Partners partners={imagesPartners.data} />
                    {/* Show just students that are not hired */}
                    <Filter students={studentsList.data.filter((student) => (!student.hired))} subDomain={subDomain} />
                    {/* <HiredStudents hiredStudents={hiredStudents.data} /> */}
                    <PeopleSay
                        title={Data.hiredStudents.title}
                        testimonials={hiredStudents.data}
                        body={Data.hiredStudents.body}
                        whereIsIt="hiring"
                        src="https://academy.brainster.co/storage/"
                    />
                    <FAQBlock />
                    <Footer
                        subDomain={subDomain}
                        title={Data.footer.title}
                        button={Data.header.button}
                    />
                </React.Fragment>
            }
        </>
    );
}

export default HomeHiring;