import React from 'react';
import styled from 'styled-components';
import Data from '../../Data';
import arrowShape from '../../img/B2B/Advantage/arrow.png'
import shape from '../../img/B2B/Advantage/shape.png'
import HirePopUp from '../Form/HirePopUp';
import usePopUp from '../Hooks/usePopUp';

const AdvantageBlock = styled.div`
    position:relative;
    padding:140px 0px;
    .left{
        position:absolute;
        left:0px;
        bottom: 0;
    }
    .right{
        position:absolute;
        right:0px; 
        bottom: 0;
        z-index:-1;
    }
    img{
        width:100%;
        height:100%;
    }
`
const AdvantageWrapper = styled.div`
   width:80%;
   margin:0 auto;
   position:relative;
   z-index:1px;
   @media (max-width:768px){
        width:100%;
        padding:0px 24px;
        box-sizing: border-box;
    }
    width:80%;
    padding:50px;
   }
`
const Title = styled.h2`
    font-size:50px;
    font-weight:bold;
    margin:0px;
    padding-bottom:60px;
    @media (max-width:425px){
        font-size:20px;
    }
    @media (min-width:426px) and (max-width:1024px){
        font-size: 30px;
    }
`
const Advantage = ({ subDomain }) => {
    const [popUp, handlePopUp] = usePopUp();
    return (
        <>
            {popUp && <HirePopUp handlePopUp={handlePopUp} subDomain={subDomain} />}
            <AdvantageBlock>
                <div className="left">
                    <img src={arrowShape} className="arrowShape" alt=""/>
                </div>
                <div className="right">
                    <img src={shape} className="shape" alt=""/>
                </div>
                <AdvantageWrapper>
                    <Title>{Data.advantage.title}</Title>
                    <button className={`button ${subDomain}`} onClick={() => handlePopUp()}>{Data.advantage.button}</button>
                </AdvantageWrapper>
            </AdvantageBlock>
        </>
    );
}

export default Advantage;