import React from 'react';
import styled from 'styled-components';
import Data from '../../Data';
import HirePopUp from '../Form/HirePopUp';
import usePopUp from '../Hooks/usePopUp';
import arrowShape from '../../img/WorkWithUs/arrowShape.png'
import shape from '../../img/WorkWithUs/shape.png'
import backgroundStarsMobile from '../../img/WorkWithUs/backgroundStarsMobile.png'
import { Headline50 } from '../FontStyle';

const WorkWithUsBlock = styled.div`
    position:relative;
    img{
        width:100%;
        height:100%;
    }
`
const WorkWithUsWrapper = styled.div`
   padding:170px 300px 96px;
   box-sizing:border-box;
   position:relative;
   z-index:1px;
   .title{
       padding-bottom:50px;
    }
   @media (max-width:425px){
    padding: 80px 24px 80px !important;   
    }
   @media (max-width:768px){
    .title{
        font-size:24px;
     }
     width:100%;
     padding: 80px 40px 80px;
   }
   @media (min-width:769px) and (max-width:1100px){
    padding: 120px 140px 80px;
   }
   @media (min-width:1100px) and (max-width:1600px){
    padding: 120px 210px 80px;
   }
   @media (min-width:2000px) {
       padding: 120px 600px 80px;
   }
`
const Left = styled.div`
   width: 100%;
   height: 100%;
   background:url(${props => props.background && props.background});
   background-repeat: no-repeat;
   background-size: 100%;
   position:absolute;
   top:0px;
   left:0px;
   @media (max-width:768px){
       display:none;
    }
`
const Right = styled.div`
   background:url(${props => props.background && props.background});
   background-size: 100%;
   background-repeat: no-repeat;
   position:absolute;
   top:0px;
   right:0px; 
   width: 100%;
   height: 100%;
   @media (max-width:768px){
        background:url(${props => props.backgroundMobile && props.backgroundMobile});
        background-repeat: no-repeat;
        right:30px;
        top:45px;
        width: 43px;
        height: 43px;
    }
`
const WorkWithUs = ({ subDomain }) => {
    const [popUp, handlePopUp] = usePopUp();
    return (
        <>
            {popUp && <HirePopUp handlePopUp={handlePopUp} subDomain={subDomain} />}
            <WorkWithUsBlock>
                <Left background={arrowShape}/>
                <Right background={shape} backgroundMobile={backgroundStarsMobile} />
                <WorkWithUsWrapper>
                    <Headline50 className="title">{Data.workWithUs.title}</Headline50>
                    <button className={`button ${subDomain}`} onClick={() => handlePopUp()}>{Data.headerInnovation.button}</button>
                </WorkWithUsWrapper>
            </WorkWithUsBlock>
        </>
    );
}

export default WorkWithUs;