import React, { useContext } from 'react';
import Data from '../../Data';
import Header from '../Header';
import { Context } from '../../Context/context';
import Projects from '../Innovation/Projects';
import WorkWithUs from '../Innovation/WorkWithUs';
import OurLatestWork from '../Innovation/OurLatestWork';
import Footer from '../Footer/Footer';
import Clients from '../Innovation/Clients';
import HowItWork from '../Innovation/HowItWork';
// images
import headerBackground from '../../img/HeaderInnovation/headerBackground.jpg'
import headerBackgroundMobile from '../../img/HeaderInnovation/headerBackgroundMobile.jpg'
import arrow from '../../img/arrow.png'
import logos from '../../img/Clients/logos.png'
import shapeOne from '../../img/HowItWork/shapeOne.png'
import shapeSecond from '../../img/HowItWork/shapeSecond.png'
import arrowMobile from '../../img/arrowIcon.png'
import shapeMobile from '../../img/HowItWork/mobile.jpg'

const HomeInnovation = ({ children, subDomain }) => {
    const { selectedLang } = useContext(Context)

    return (
        <>
            {children}
            <Header
                subDomain={subDomain}
                title={Data.headerInnovation.title}
                body={Data.headerInnovation.body}
                button={Data.headerInnovation.button}
                background={headerBackground}
                backgroundMobile={headerBackgroundMobile}
                fill="#F8F8F8"
                selectedLang={selectedLang}
            />
            <HowItWork
                data={Data.howItWork}
                arrow={arrow}
                shapeOne={shapeOne}
                shapeSecond={shapeSecond}
                arrowMobile={arrowMobile}
                shapeMobile={shapeMobile}
            />
            <OurLatestWork />
            <WorkWithUs subDomain={subDomain} />
            <Projects subDomain={subDomain} />
            <Clients subDomain={subDomain} title={Data.clients.title} logos={logos} />
            <Footer
                subDomain={subDomain}
                title={Data.footerInnovation.title}
                button={Data.headerInnovation.button}
            />
        </>);
}

export default HomeInnovation;