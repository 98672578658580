import React, { useContext } from 'react';
import styled from 'styled-components';
import { Context } from '../Context/context';
import { Text20Bold } from './FontStyle';

const LanguagesWrapper = styled.div`
    background:#F8F8F8;
    box-shadow:0px 3px 15px 20px rgba(0,0,0,0.014);
    padding:18px 40px;
    display:flex;
    border-radius:43px;
    .color{
      color:#161616;
      margin-left:10px;
      &:first-child {
        margin-left:0px;
      }
    }
    .languagesItems{
      color:#BFBFBF;
      margin-left:10px;
      &:first-child {
        margin-left:0px;
    }
    }
  @media (max-width:768px){
    padding:80px 0px;
    background:transparent;
    box-shadow:none;
  }
`
const SetLanguages = ({ padding }) => {
  const { selectedLang, selectedLanguages } = useContext(Context)
  return (
    <LanguagesWrapper padding={padding}>
      <Text20Bold className={selectedLang === "en" ? "color" : "languagesItems"} onClick={() => selectedLanguages("en")}>EN</Text20Bold>
      <Text20Bold className="languagesItems">|</Text20Bold>
      <Text20Bold className={selectedLang === "mk" ? "color" : "languagesItems"} onClick={() => selectedLanguages("mk")}>MK</Text20Bold>
    </LanguagesWrapper>
  );
}

export default SetLanguages;