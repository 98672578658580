import React from 'react';
import styled from 'styled-components';
import Data from '../../Data';
import { Headline50, Text20Normal } from '../FontStyle';
import arrow from '../../img/arrow.png'
import usePopUp from '../Hooks/usePopUp';
import HirePopUp from '../Form/HirePopUp';
import CardBox from '../CardBox';
import Slider from '../Slider'

const ProjectsBlock = styled.div`
   background:#393C4E;
   padding:50px 0px;
`
const ProjectsWrapper = styled.div`
   width:80%;
   margin:0 auto;
   .white{
       color:#fff;
    }
   .title{
       width:85%;
       margin-bottom:30px;
    }
    @media (max-width:768px){
        width:100%;
        .title{
           padding:0px 40px;
        }
    }
    @media (max-width:425px){
        .title{
            padding:0px 24px !important;
         }
       }
`

const Text = styled.div`
   width:45%;
   white-space:pre-line;
   margin-left:100px;
   display:grid;
   grid-template-columns: 0.03fr 1fr;
   grid-gap:20px;
   box-sizing: border-box;
   @media (min-width:769px) and (max-width:1440px){
        width:70%;
        .image img{
            width:18px;
        }
   }
   @media (max-width:768px){
     margin-left:0px;
     width:100%;
     padding:0px 40px 40px 40px;
     .image img{
        width:15px;
     }
    }
    @media (max-width:425px){
        padding:0px 24px 40px 24px !important;
    }
`
const Desktop = styled.div`
  @media (max-width:768px){
    display:none;
   }
`
const Mobile = styled.div`
   display:none;
   @media (max-width:768px){
      display:block;
   }
`


const Projects = ({ subDomain, props }) => {
    const [popUp, handlePopUp] = usePopUp();
    return (
        <>
            {popUp && <HirePopUp handlePopUp={handlePopUp} subDomain={subDomain} />}
            <ProjectsBlock>
                <ProjectsWrapper>
                    <Headline50 className="white title">{Data.projects.title}</Headline50>
                    <Text>
                        <div className="image">
                            <img src={arrow} alt="" />
                        </div>
                        <Text20Normal className="white">
                            {Data.projects.text}
                        </Text20Normal>
                    </Text>
                    <Desktop>
                        <CardBox handlePopUp={handlePopUp} cardsToShow={Data.projects.cards} hoverBorder="#FBD132" />
                    </Desktop>
                    <Mobile>
                        <Slider handlePopUp={handlePopUp} slideImages={Data.projects.cards} hoverBorder="#FBD132" whereIsIt="projects" />
                    </Mobile>
                </ProjectsWrapper>
            </ProjectsBlock>
        </>);
}

export default Projects;