import React, { useContext, useLayoutEffect } from 'react';
import Data from '../../Data';
import Header from '../Header';
import ModalGallery from '../InnovationLatesWork/ModalGallery';
import HowItWorks from '../Innovation/HowItWorks';
import { Context } from '../../Context/context';
import SliderGallery from '../InnovationLatesWork/SliderGallery';
import Results from '../InnovationLatesWork/Results';
import ContactUs from '../InnovationLatesWork/ContactUs';
import SimilarProjects from '../InnovationLatesWork/SimilarProjects';
import Footer from '../Footer/Footer'
// images
import headerBackground from '../../img/LatestWork/header/header.jpg'
import flag from '../../img/LatestWork/challenge/flag.png'
import star from '../../img/LatestWork/challenge/star.png'
import shape from '../../img/LatestWork/OurApproach/shape.png'

const HomeLatesWork = ({ children, id, subDomain }) => {
    const { selectedLang } = useContext(Context)
    let projectToShow = Data.ourLatestWork.projectsText.find(el => parseInt(el.id) === parseInt(id));

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            {children}
            <Header
                subDomain="project"
                title={projectToShow.projectData.header.title}
                body={projectToShow.projectData.header.text}
                background={headerBackground}
                aboveTitleText={Data.ourLatestWork.aboveTitleText}
                backgroundMobile={require(`../../img/LatestWork/${projectToShow.id}/Footer.png`).default}
                fill="#161616"
                selectedLang={selectedLang}
                projectToShow={projectToShow}
            />
            <ModalGallery projectToShow={projectToShow} background={require(`../../img/LatestWork/${projectToShow.id}/Background.png`).default} />
            <HowItWorks
                projectToShow={projectToShow}
                whereIsIt="innovation"
                title={Data.ourLatestWork.challengeTitle}
                textRight={projectToShow.projectData.challenge.textRight}
                textRightUnder={projectToShow.projectData.challenge.textRightUnder}
                textLeft={projectToShow.projectData.challenge.textLeft}
                textLeftUnder={projectToShow.projectData.challenge.textLeftUnder}
                iconRight={flag}
                iconLeft={star}
                challengeMobile={Data.ourLatestWork.challengeMobile}
                solutionMobile={Data.ourLatestWork.solutionMobile}
            />
            <SliderGallery
                projectToShow={projectToShow}
            />
            <HowItWorks
                projectToShow={projectToShow}
                whereIsIt="innovationOurApproach"
                title={Data.ourLatestWork.titleOurApproach}
                textRight={projectToShow.projectData.OurApproach.textRight}
                textRightUnder={projectToShow.projectData.OurApproach.textRightUnder}
                iconRight={shape}
            />
            <Results
                projectToShow={projectToShow}
                title={Data.ourLatestWork.titleResults}
            />
            <ModalGallery background={require(`../../img/LatestWork/${projectToShow.id}/Footer.png`).default} whereIsIt='justBackground' />
            <ContactUs />
            <SimilarProjects projectToShow={projectToShow} />
            <Footer
                subDomain="innovation"
                title={Data.ourLatestWork.footer}
                button={Data.headerInnovation.button} />
        </>
    );
}

export default HomeLatesWork;