import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Data from '../../Data';
import arrowShape from '../../img/LatestWork/contactUs/arrow.png'
import shape from '../../img/LatestWork/contactUs/shape.png'
import mobileArrow from '../../img/LatestWork/contactUs/mobileArrow.png'
import { SuccessMessage } from '../Form/HireAStudent';


const ContactUsBlock = styled.div`
    position:relative;
    .left{
        position:absolute;
        top:-3px;
        left:0px;
        z-index:-1;
    }
    .right{
        position:absolute;
        top:-163px;
        left:-130px; 
        z-index:-1;
    }
    img{
        width:100%;
        height:100%;
    }
    @media (max-width:768px){
        .left{
            display:none;
        }
        .right{
            position:absolute;
            top:0px;
            left:0px; 
            z-index:-1;
        }
    }
`
const WorkWithUsWrapper = styled.div`
   width:80%;
   margin:0 auto;
   display: grid;
   grid-template-columns: 1fr 0.7fr;
   align-items: stretch;
   padding:160px 0px;
   @media (max-width:768px){
    width:100%;
    padding:60px 40px 80px 40px;
    box-sizing:border-box;
    grid-template-columns: 1fr;
   }
   @media (max-width:425px){
     padding:60px 24px 80px 24px !important;
   }
`
const Title = styled.h2`
    font-size:40px;
    font-weight:bold;
    margin:0px;
    align-self: end;
    @media (max-width:768px){
        font-size:32px;
        padding-bottom: 50px;
    }
    @media (max-width:425px){
        font-size:28px;
    }
`
const ContactUsText = styled.div`
    justify-self: center;
    position:relative;
    button{
        visibility:${props => props.copySuccess ? "visible" : "hidden"};
        margin:0px;
        padding:5px 10px;
    }
    .contactUs{
      font-size:32px;
      font-weight:bold;
      color:#161616;
      margin-bottom:0px;
    }
    .mail{
      margin:0px;
      font-size:26px;
      font-weight:500;
      color:#161616;
      border-bottom:2px solid #161616;
      padding: 10px 0px;
      cursor:grab;
    }
    @media (max-width:768px){
        justify-self: stretch;
        .contactUs{
            font-size:22px;
        }
        .mail{
            font-size:18px;
        }
    }
`
const Right = styled.div`
   background:url(${props => props.background && props.background});
   background-size: 100%;
   background-repeat: no-repeat;
   position:absolute;
   top:0px;
   right:0px; 
   width: 100%;
   height: 100%;
   @media (max-width:768px){
        background:url(${props => props.backgroundMobile && props.backgroundMobile});
        background-size: 100%;
        background-repeat: no-repeat;
        right:0;
        top:60px;
    }
    @media (max-width:580px){
        top:45% !important;
    }
`
const ContactUs = () => {
    const [copySuccess, setCopySuccess] = useState("")

    useEffect(() => {
        const interval = setInterval(() => {
            if (copySuccess) {
                setCopySuccess(false)
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [copySuccess]);

    return (
        <ContactUsBlock>
            <WorkWithUsWrapper>
                <Right background={arrowShape} backgroundMobile={mobileArrow} />
                <div className="left">
                    <img src={shape} className="shape" alt="" />
                </div>
                <Title>{Data.ourLatestWork.contactUstext}</Title>
                <ContactUsText copySuccess={copySuccess}>
                    <p className="contactUs">{Data.ourLatestWork.contactUs}</p>
                    <SuccessMessage>{Data.ourLatestWork.successMessage}</SuccessMessage>
                    {Data.footer.contacts.map((contact, i) => (
                        <p
                            key={i}
                            className="mail"
                            onClick={() => { navigator.clipboard.writeText(contact.email); setCopySuccess(true) }}>
                            {contact.email}
                        </p>
                    ))}
                </ContactUsText>
            </WorkWithUsWrapper>
        </ContactUsBlock>
    );
}

export default ContactUs;