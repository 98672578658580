import styled from 'styled-components';

//H1 is same sa Headline74 below
//it was added for SEO purposes
export const H1 = styled.h1`
    font-size:74px;
    font-weight:bold;
    color:#000000;
    font-family: 'Montserrat';
    @media (max-width:425px){
        font-size:34px;
    }
    @media (min-width:426px) and (max-width:1600px){
        font-size: 50px;
    }
`;


// Headline

export const Headline74 = styled.p`
    font-size:74px;
    font-weight:bold;
    color:#000000;
    font-family: 'Montserrat';
    @media (max-width:425px){
        font-size:34px;
    }
    @media (min-width:426px) and (max-width:1600px){
        font-size: 50px;
    }
`;

export const Headline50 = styled.p`
    font-size:50px;
    font-weight:bold;
    color:#161616;
    margin:0px;
    font-family: 'Montserrat';
    @media (max-width:768px){
        font-size:32px;
    }
    @media (min-width:768px) and ( max-width:1600px){
        font-size:34px;
    }
`;

export const Headline44 = styled.p`
    font-size:44px;
    font-weight:bold;
    color:#3A3A3A;
    font-family: 'Montserrat';
    @media (max-width:768px){
        font-size:18px;
    }
    @media (min-width:769px) and ( max-width:1100px){
        font-size:28px;
    }
`;

// Text
export const Text34Bold = styled.p`
    font-size:34px;
    font-weight: bold;
    color:#000000;
    font-family: 'Montserrat';
    // margin:0px;
    // font-size: calc(18px + (34 - 18) * ((100vw - 300px) / (1900 - 300)));
    @media (max-width:768px){
        font-size:18px;
    }
    @media (min-width:769px) and ( max-width:1600px){
        font-size:26px;
    }

`
export const Text24Bold = styled.p`
    font-size:24px;
    font-weight: bold;
    color:#3A3A3A;
    font-family: 'Montserrat';
    margin:0px;
    padding-bottom:6px;
    @media (max-width:768px){
        font-size:14px;
    }
    @media (min-width:769px) and ( max-width:1600px){
        font-size:20px;
    }
`;
export const Text24Normal = styled.p`
    font-size:24px;
    font-weight: normal;
    color:#3A3A3A;
    font-family: 'Montserrat';
    margin:0px;
    line-height:32px;
    @media (max-width:425px){
        font-size:16px;
        line-height:22px;
    }
    @media (min-width:768px) and (max-width:1020px){
        font-size:18px;
        padding:10px 0px;
        line-hight:35px;
    }
    @media (min-width:1020px) and ( max-width:1600px){
        font-size:20px;
    }
`;
export const Text14Normal = styled.p`
    font-size:14px;
    font-weight: normal;
    color:#000000;
    font-family: 'Montserrat';
    margin:0px;
    @media (max-width:768px){
        font-size:12px;
    }
`;
export const Text14Bold = styled.p`
    font-size:14px;
    font-weight: bold;
    color:${props => props.color};
    font-family: 'Montserrat';
    margin:0px;
    @media (max-width:1500px){
        font-size:12px;
    }
`;
export const Text18Normal = styled.p`
    font-size:18px;
    font-weight: normal;
    color:#3A3A3A;
    font-family: 'Montserrat';
    margin:0px;
    margin-left:${props => props.margin && "10px"};
    @media (max-width:768px){
        font-size:14px;
    }
`;
export const Text20Bold = styled.p`
    font-size:20px;
    font-weight: bold;
    color:${props => props.color};
    font-family: 'Montserrat';
    margin:0px;
    cursor:pointer;
    margin-left:${props => props.margin};
    @media (max-width:768px){
        font-size:14px;
    }
    @media (min-width:768px) and (max-width:1600px){
        font-size:18px;
    }
`;

export const Text20Normal = styled.p`
    font-size:20px;
    font-weight: normal;
    color:#3A3A3A;
    font-family: 'Montserrat';
    margin:0px;
    cursor:pointer;
    @media (max-width:768px){
        font-size:14px;
    }
    @media (min-width:768px) and (max-width:1600px){
        font-size:18px;
    }
`;

