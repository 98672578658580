import { useState } from 'react';

const usePopUp = () => {

    const [popUp, setPopUp] = useState(false)

    const handlePopUp = () => {
        setPopUp(!popUp)
    }
    return [popUp, handlePopUp];
}

export default usePopUp;