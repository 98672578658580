import React, { useState } from 'react';
import styled from 'styled-components';
import FAQ from './FAQ';
import faqBackground from '../../../img/FAQ/shape-FAQ-full.png';
import { Headline74 } from '../../FontStyle';
import Data from '../../../Data';


const FAQSection = styled.div`
    position:relative;
`
const FAQBlockWrapper = styled.div`
    width:80%;
    margin:0 auto;
    font-family:Montserrat;
    padding:20px 0px 170px 0px;
    color:#000000;
    @media (max-width:768px){
        width:85%;
        padding:20px 0px 60px 0px;
    }
    @media (min-width:769px) and (max-width:1440px){
        padding:20px 0px 100px 0px;
    }
`
const Background = styled.div`
    position:absolute;
    bottom:0;
    left:0;
    z-index:-1;
    img{
        height:100%;
        width:100%; 
    }
`
const FAQBlock = () => {
    // logic about open/close clicked plus sign
    // Check if the id is in the list, if it not, put it in the list. 
    const [elementOpen, setElementOpen] = useState(0)

    const toggleFAQ = (index) => {
        if (elementOpen === index) {
            setElementOpen(0)
        } else {
            setElementOpen(index)
        }
    }
   
    return (
        <FAQSection>
            <Background>
                <img src={faqBackground} alt="shape" />
            </Background>
            <FAQBlockWrapper>
                <Headline74>{Data.faqs.heading}</Headline74>
                <div className="faqs">
                    {Data.faqs.faqs.map((element, i) => (
                        <FAQ faq={element} toggleFAQ={toggleFAQ} key={i} index={elementOpen} />
                    ))
                    }
                </div>
            </FAQBlockWrapper>
        </FAQSection>
    );

}

export default FAQBlock;