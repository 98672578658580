import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Text24Normal, Text34Bold } from '../../FontStyle';
import PlusIcon from './PlusIcon';

const QuestionAndAnswers = styled.div`
    text-align: start;
    cursor:pointer;
   .questions{
       display: flex;
       justify-content: space-between;
       align-items: center;
       transition: all .4s ease;
       border-bottom: 1px solid #B3B4B5;
   }
   .answer{
      transition: all .4s ease-out;
   }
   .answer.close{
       overflow-y: hidden;
       opacity: 0;
       max-height: 0;
       overflow-y: hidden;
       margin-left: 3rem;
       width: 70%;
   }
   .answer.open{
       overflow-y: hidden;
       opacity: 1;
       padding:40px;
   }
   @media (max-width:768px){
    .answer.open{
      padding: 20px 10px;
      }
    }
    .question{
        width: 90%;
    }
`
const Image = styled.div`
    cursor:pointer;
    transition: all .4s ease;
    &.rotate{
        transform: rotateZ(45deg);
        transition: all 0.6s ease 0s;
      }
`
const FAQ = ({ faq, toggleFAQ, index }) => {
    const [width, setWidth] = useState(31.254)

    // to set width and height on plus image
    useEffect(() => {
        if (window.innerWidth <= 500) {
            setWidth(22.1)
        }
    }, [])

    return (
        <QuestionAndAnswers>
            <div className="questions" onClick={() => toggleFAQ(faq.id)}> 
                <Text34Bold className="question">{faq.question}</Text34Bold>
                <Image onClick={() => toggleFAQ(faq.id)} className={index === faq.id ? "rotate" : ""}>
                    <PlusIcon fill={index === faq.id ? "#FBD132" : "#000000"} width={width} height={width} />
                </Image> 
             </div>
            <Text24Normal className={index === faq.id ? "answer open" : "answer close"}>{faq.answer}</Text24Normal>
        </QuestionAndAnswers>
    );
}

export default FAQ;