import { Link } from '@reach/router';
import React from 'react';
import styled from 'styled-components';
import Data from '../../Data';
import { Headline50, Text14Bold } from '../FontStyle';
import Slider from '../Slider'

const OurLatestWorkWrapper = styled.div`
   width:80%;
   margin:0 auto;
   .image{
       border-radius:20px;
   }
   .allImages{
       width:100%;
       position:relative;
    }
   @media (max-width:425px){
    .mainTitle{
        padding:0 24px !important;
      }
   }
   @media (max-width:768px){
      width:100%;
      .mainTitle{
        padding:0 40px;
      }
    }
`
const PictureGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 12vw);
    grid-gap: 15px;
    padding:75px 0px 0px;
   .galeryImage1{
      grid-column-start: 1;
      grid-column-end: 5;
      grid-row-start: 1;
      grid-row-end: 3;
   }
   .galeryImage2 {
      grid-column-start: 5;
      grid-column-end: 7;
      grid-row-start: 1;
      grid-row-end: 5;
    }
   .galeryImage3 {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 3;
        grid-row-end: 5;
    }
    .galeryImage4 {
        grid-column-start: 3;
        grid-column-end: 5;
        grid-row-start: 3;
        grid-row-end: 7;
    }
    .galeryImage5 {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 5;
        grid-row-end: 7;
    }
    .galeryImage6 {
        grid-column-start: 5;
        grid-column-end: 7;
        grid-row-start: 5;
        grid-row-end: 7;
    }
    @media (max-width:768px){
        display:none;
    }
`
const Text = styled.div`
   position:absolute;
   bottom:5px;
   padding:40px;
   p{
       color:white;
   }
   .title{
       font-size:35px;
       font-weight:bold;
       margin:0px;
       padding-top:10px;
       @media (max-width:768px){
        font-size:14px;
       }
   }
   @media (max-width:1600px){
    padding:20px;
    .title{
        font-size:25px;
    }
   }
`
const Mobile = styled.div`
    display:none;
    @media (max-width:768px){
        display:block;
        padding:27px 0px 0px;
        img{
            width: 100%;
            height:400px;
        }
    }
`
const Background = styled.div`
    width: 100%;
    height: 100%;
    background: linear-gradient(
        to bottom,
        rgba(0,0,0,0%),
        rgba(0,0,0,100%)
      ), url(${props => props.desktop});
    background-repeat: no-repeat;
    border-radius:20px;
    background-size: cover;
    @media (max-width:768px){
        background: linear-gradient(
            to bottom,
            rgba(0,0,0,0%),
            rgba(0,0,0,100%)
          ), url(${props => props.mobile});
        background-repeat: no-repeat;
        background-size: cover;
        // height:400px;
        height:calc(100vh - 400px);
    }
`
export const ProjectImages = ({ card, index }) => (
    <div className={`allImages galeryImage${index + 1}`}>
        <Link to={`/Project/${card.id}`}>
            <Background desktop={require(`../../img/OurLatestWork/${index + 1}.png`).default} mobile={require(`../../img/OurLatestWork/Mobile/${index + 1}.png`).default}/>            <Text>
                <Text14Bold>{Data.ourLatestWork.project}</Text14Bold>
                <p className="title">{card.titleInPicture}</p>
            </Text>
        </Link>
    </div>
)
const OurLatestWork = () => {

    return (
        <OurLatestWorkWrapper>
            <Headline50 className="mainTitle">{Data.ourLatestWork.title}</Headline50>
            <PictureGrid>
                {Data.ourLatestWork.projectsText.map((project, index) => (
                    <ProjectImages key={index} card={project} index={index} />
                ))}
            </PictureGrid>
            <Mobile>
                <Slider slideImages={Data.ourLatestWork.projectsText} />
            </Mobile>
        </OurLatestWorkWrapper >
    );
}

export default OurLatestWork;