import React from 'react';
import styled from 'styled-components';
import Data from '../Data';
import { Text14Bold } from './FontStyle';
import { Touch } from './icons';

const SwipeLeft = styled.div`
    color:#A8A8A8;
    display: flex;
    justify-content: flex-end;
    align-items: center; 
    svg{ 
      position:relative;
      top:0px;
      animation: mymove 0.5s cubic-bezier(0.19, 1, 0.22, 1) infinite;
    } 
    @keyframes mymove{
      0%{
        left:0px;
      }
      100%{
        left:10px;
      }
    } 
`

const TouchIcon = () => {
    return (
        <SwipeLeft>
            <Text14Bold>{Data.ourLatestWork.swipeLeft}</Text14Bold>
            <Touch />
        </SwipeLeft>
    );
}

export default TouchIcon;