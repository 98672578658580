import React from 'react';
import styled from 'styled-components';
import { Headline74, Text24Normal } from '../FontStyle';
import verified from '../../img/LatestWork/results/verified.png'

const ResultsWrapper = styled.div`
   width:80%;
   margin:0 auto;
   .title{
      margin:0px;
      margin-bottom:-50px;
    }
    @media (max-width:768px){
        width:100%;
        padding:0px 40px;
        box-sizing: border-box;
        .title{
            margin:0px;
            margin-bottom:24px;
    }
    @media (max-width:425px){
        padding:0px 24px !important;
    }
`
const Grid = styled.div`
   display: grid;
   grid-template-columns: 1fr 1fr;
   grid-gap: 179px;
   padding:0px 0px 100px;
   @media (max-width:768px){
      padding:0px 0px 30px;
      grid-template-columns: 1fr;
      grid-gap:40px;
   }
`
const Left = styled.div`
   align-self: end;
    .number{
        font-weight:500;
        margin:0px;
        text-align:center;
    }
    .numberText{
        color:#A8A8A8;
        font-weight:500;
    }
    @media (max-width:768px){
        order: 2;
        .numberText{
            text-align:center;
            font-size:18px;
        }
        .number{
            font-size:70px;
        }
    }
`
const Right = styled.div`
  ul{
      list-style-type: none;
      margin:0px;
      padding:0;
      li{
        font-size:20px;
        color:#3A3A3A;
        background: url(${verified}) left top no-repeat;
        padding-left: 50px;
        padding-bottom: 32px;
        padding-top:5px;
        box-sizing:border-box;
      }
    }
    @media (max-width:768px){
        ul{
            padding:0px;
            li{
                font-size:16px;
                font-weight: 500;
                background-size: 25px 25px;
            }
        }
    }
`
const Numbers = styled.div`
    display: flex;
    width:70%;
    justify-content: space-between;
    div{
        justify-self: start;
        padding-right:10px;
    }
    @media (max-width:1440px){
        width:100%;
    }
    @media (max-width:768px){
       display:block;
       div{
           padding-bottom:40px;
       }
    }
`
const Results = ({ title, projectToShow }) => {
    return (
        <ResultsWrapper>
            <Headline74 className="title">{title}</Headline74>
            <Grid>
                <Left>
                    <Numbers>
                        {projectToShow.projectData.resultNumbers.map((element, index) => (
                            <div key={index}>
                                <Headline74 className="number">{element.number}</Headline74>
                                <Text24Normal className="numberText">{element.type}</Text24Normal>
                            </div>
                        ))}
                    </Numbers>
                </Left>
                <Right>
                    <ul>
                        {projectToShow.projectData.resultsBullet.map((element, index) => (
                            <li key={index}>{element}</li>
                        ))}
                    </ul>
                </Right>
            </Grid>
        </ResultsWrapper>);
}

export default Results;