import React from 'react';
import styled from 'styled-components';
import Data from '../../Data';
import { Text20Bold } from '../FontStyle';
import SetLanguages from '../SetLanguages';
import {  Link } from "@reach/router";

const NavBarWrapper = styled.div`
    width:83%;
    margin:0 auto;
    display: grid;
    grid-gap: 50px;
    grid-template-columns: 1fr 0.1fr;
    @media (max-width:768px){
        display:none;
    }
`
const NavMenu = styled.div`
    padding:0px 3%;
    background:#F8F8F8;
    box-shadow:0px 3px 15px 20px rgba(0,0,0,0.014);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    justify-self: flex-start;
    border-radius:43px;
    img{
        margin-top:-30px;
    }
    .menuItems{
        margin-left:60px;
        &:first-child {
            margin-left:0px;
        }
        a{
            color:#161616;
            text-decoration: none;
        }
        .active{
            color:#FBD132;
        }
    }
    @media (max-width:1440px){
        .menuItems{ 
            margin-left:30px;
        &:first-child {
            margin-left:0px;
        }
      }
    }
`

export const NavBarList = ({ subDomain }) => (
    <>
        <Text20Bold className="menuItems"><a href="https://hiring.brainster.io/" className={subDomain === 'hiring' ? "active" : null}>{Data.navBar.one}</a></Text20Bold>
        <Text20Bold className="menuItems"><a href="https://innovation.brainster.io/" className={subDomain === 'innovation' || subDomain === 'project' ? "active" : null}>{Data.navBar.two}</a></Text20Bold>
        <Text20Bold className="menuItems"><Link to="B2B">{Data.navBar.three}</Link></Text20Bold>
    </>
)
const NavBar = ({ subDomain }) => {
    return (
        <>
            <NavBarWrapper>
                <NavMenu>
                    <NavBarList subDomain={subDomain}/>
                </NavMenu>
                {/*<SetLanguages />*/}
            </NavBarWrapper>
        </>
    );
}

export default NavBar;
