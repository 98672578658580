import React from 'react';
import styled from 'styled-components';
import bgImage from '../img/SelectedCandidates/bgImage.jpg'
import circle from '../img/404.svg'
import { Text24Bold } from './FontStyle';

const ErrorPageSection = styled.div`
    width:100%;
    background-image:url(${bgImage});
    background-position:top center;
    background-size: cover;
    position:absolute;
    bottom:0;
    right: 0;
    top:0;
    z-index:-1;
    background-repeat: no-repeat;
`
const ErrorPageWrapper = styled.div`
   height:100%;
   display:flex;
   justify-content:center;
   align-items:center;
   .number{
       font-size: 90px;
       color: #FBD132;
   }
`
const ErrorNumberText = styled.div`
   text-align: center;
   p{
    padding-top: 50px;
   }
`
const Image = styled.img`
   padding:0px 10px;
`
const Number = styled.span`
   font-style: normal;
   font-size: 96px;
   color:#FBD132;
   font-weight:bold;
   line-height: 70px;
`
const ErrorPage = () => {
    return (
        <ErrorPageSection>
            <ErrorPageWrapper>
                <ErrorNumberText>
                    <Number>4</Number>
                    <Image src={circle} />
                    <Number>4</Number>
                    <Text24Bold>Страницата не е пронајдена</Text24Bold>
                </ErrorNumberText>
            </ErrorPageWrapper>
        </ErrorPageSection>
    );
}

export default ErrorPage;