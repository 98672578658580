import React from 'react';
import PopUp from '../PopUp/PopUp';
import styled from 'styled-components';
import ExitButton from '../PopUp/ExitButton';

// images

import shape from '../../img/PopUp/CardDetails/shape.png';
import circle from '../../img/PopUp/CardDetails/circle.png';

import linkedin from '../../img/Icons/linkedin.svg';
import website from '../../img/Icons/website.svg';

// cooding
import github from '../../img/Icons/github.svg';

// disign
import dribble from '../../img/Icons/dribble.svg';
import behance from '../../img/Icons/behance.svg';

//social
import twitter from '../../img/Icons/twitter.svg';
import facebook from '../../img/Icons/facebook.svg';
import upwork from '../../img/Icons/upwork.svg';


import Data from '../../Data';


const CardDetailsSection = styled.div`
    width:80%;
    position: fixed;
    top: 50%;
    left:50%;
    transform: translate(-50%,-50%);
    margin: 0;
    z-index: 13;
    background:#FFFFFF;
    .header{
        display:flex;
        justify-content:center;
    }
    @media(max-width:768px){
        width:95%;
        max-height:90vh;
        overflow-y: scroll;
    }
    @media(max-height:900px){
        max-height:90vh;
        overflow-y: scroll;
    }  
`
const CardDetailsBlock = styled.div`
    position:relative;
    height:100%;
`
const CardDetailsTextWrapper = styled.div`
  width:80%;
  height:100%;
  box-sizing:border-box;
  padding:60px 0px;
  margin:0 auto;
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap:100px;
  align-items:center;
  @media(max-width:768px){
    grid-template-columns: 1fr;
    grid-gap: 0;
    height: auto;
    padding:40px 0px;
    width:90%;
  }
  @media (min-width:769px) and (max-width:1440px){
    width:80%;
    grid-gap: 20px;
  }
`
const Left = styled.div`
    .name{
        font-size:36px;
        font-weight:bold;
        color:#3A3A3A;
        margin:0px;
        margin-bottom:3px;
        }
    .profession{
        font-size:24px;
        font-weight:normal;
        color:#3A3A3A;
        margin:0px;
    }
    .mobile{
       display:block;
    }
    @media (max-width:768px){
        width:100%;
        padding-right:0px;
        .name{
            font-size:16px;
        }
        .profession{
            font-size:14px;
        }
        .mobile{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    @media (min-width:769px) and (max-width:1500px){
        .name{
            font-size:26px;
        }
        .profession{
            font-size:24px;
        }
    }
  
`
const Right = styled.div`
    display:flex;
    flex-direction:column;
    .titleCard{
        font-size:24px;
        font-weight:bold;
        color:#3A3A3A;
    }
   button{
    font-style: normal;
    font-weight: normal;
    font-size: 20px; 
    border:1px solid #CBCBCB;
    background:transparent;
    padding:5px 30px;
    margin-right:15px;
    margin-bottom:10px;
    font-family: 'Montserrat';
    :focus{
        outline:none
    }
   }
   @media (max-width:768px){
    width:100%;
    .titleCard{
        font-size:12px;
    }
    button{
        font-size:12px;
        margin-right:10px;
        margin-bottom:5px;
    }
 }
 @media (min-width:769px) and (max-width: 1500px){
    width:100%;
    button{
        font-size:14px;
        margin-right:10px;
        margin-bottom:5px;
    }
 }
`

const Background = styled.div`
    position: absolute;
    background-image:url(${shape});
    background-repeat: no-repeat;
    background-size:contain;
    z-index: -1;
    height: 100%;
    width: 100%;
    @media (max-width:1440px){
        display:none;
    }
`
const Icons = styled.div`
    padding:40px 0px 0px;
    display:flex;
    align-items:center;
    .cv{
        font-size:28px;
        font-weight:bold;
        color:#3A3A3A;
    }
    a{
        margin-right:30px;
        display:block;
    }
    @media (max-width:768px){
        padding:20px 0px;
        img{
            padding-right:0px; 
        }
    }
`
const First = styled.div`
    @media (max-width:768px){
        order: 2;
        padding-left:10px;
    }
`
const Second = styled.div`
    order: 2;
    position:relative;
    width:400px;
    height:300px;
    margin:30px 0px;
    .imgStudent{
        position: absolute;
        width:300px;
        height:300px;
        top:0px;
        img{
            border-radius:50%;
            object-fit: cover;
        }
    }
    .circleMobile{
        position:absolute;
        top:0px;
        right:0px;
        z-index:-1;
    }
    img{
        width:100%;
        height:100%;
    }
    @media (max-width:768px){
        order: 1;
        position:relative;
        width:135px;
        height:100px;
        .imgStudent{
            width:100px;
            height:100px;
            right: 0;
        }
        .circleMobile{
            left:0px;
            transform: rotate(260deg);
            width: 80px;
            height: 78px;
        }
    }
    @media (min-width:769px) and (max-width:1500px){
        width:260px;
        height:220px;
        .imgStudent{
            width:220px;
            height:220px;
        }
        .circleMobile{
            width:150px;
            height:150px;
        }
    }
`
const Third = styled.div`
    order: 3;
    .borderBottom{
        border-bottom:1px solid #3A3A3A;
    }
    .color{
        color:#FBD132;
    }
    .academy{
        font-size:16px;
    }
    @media (max-width:768px){
        .academy{
            font-size:12px;
            text-align:center;
        }
    }
    @media (min-width:769px) and (max-width: 1500px){ 
        .academy{
            font-size:14px;
        } 
    }
`
const Fourth = styled.div`
    order: 4;
    border-bottom:2px solid #ECECEC;
    .textBio{
        font-size:20px;
        font-weight:normal;
        color:#3A3A3A;
        margin:0px;
        padding-bottom:40px;
    }
    @media (max-width:768px){
        order:6;
        .textBio{
            font-size:14px;
        }
    }
    @media (min-width:769px) and (max-width: 1500px){
        .textBio{
            font-size:14px;
        }
    }
`
const Fifth = styled.div`
    order: 5;
    @media (max-width:768px){
        order:4;
    }
   
`
const Sixth = styled.div`
    order: 6;
    @media (max-width:768px){
        order:5;
    }
   
`
const CardDetails = ({ handlePopUp, card, selectedLang }) => {
    return (
        <>
            <PopUp />
            <CardDetailsSection>
                <CardDetailsBlock>
                    <Background />
                    <ExitButton handlePopUp={handlePopUp} />
                    <CardDetailsTextWrapper>
                        <Left>
                            <div className="mobile">
                                <First>
                                    <p className="name">{selectedLang === "en" ? card.name_en : card.name}</p>
                                    <p className="profession">{Data.filterMenu.find((el, i) => el.id === card.academyId).title}</p>
                                </First>
                                <Second>
                                    <div className="imgStudent">
                                        <img src={`https://academy.brainster.co/storage/${card.profileImg}`} alt="student" />
                                    </div>
                                    <div className="circleMobile">
                                        <img src={circle} alt="shape" />
                                    </div>
                                </Second>
                            </div>
                            <Third>
                                <p className="academy">{Data.cardDetails.textUnderPicture} @ <span className="borderBottom">Brainster</span> <span className="color">•</span>{Data.filterMenu.find((el, i) => el.id === card.academyId).academy}</p>
                                <Icons>
                                    {card.cv && <a href={`https://academy.brainster.co/storage/${card.cv}`} target="_blank" rel="noreferrer" className="cv" >CV</a>}
                                    {card.socialProfiles && card.socialProfiles.linkedin && <a href={card.socialProfiles.linkedin} target="_blank" rel="noreferrer" ><img src={linkedin} alt="linkedin" /></a>}
                                    {card.socialProfiles && card.socialProfiles.website && <a href={card.socialProfiles.website} target="_blank" rel="noreferrer" ><img src={website} alt="website" /></a>}
                                    {card.socialProfiles && card.socialProfiles.behance && <a href={card.socialProfiles.behance} target="_blank" rel="noreferrer" ><img src={behance} alt="behance" /></a>}
                                    {card.socialProfiles && card.socialProfiles.dribble && <a href={card.socialProfiles.dribble} target="_blank" rel="noreferrer" ><img src={dribble} alt="dribble" /></a>}
                                    {card.socialProfiles && card.socialProfiles.github && <a href={card.socialProfiles.github} target="_blank" rel="noreferrer" ><img src={github} alt="github" /></a>}
                                    {card.socialProfiles && card.socialProfiles.facebook && <a href={card.socialProfiles.facebook} target="_blank" rel="noreferrer" ><img src={facebook} alt="facebook" /></a>}
                                    {card.socialProfiles && card.socialProfiles.twitter && <a href={card.socialProfiles.twitter} target="_blank" rel="noreferrer" ><img src={twitter} alt="twitter" /></a>}
                                    {card.socialProfiles && card.socialProfiles.upwork && <a href={card.socialProfiles.upwork} target="_blank" rel="noreferrer" ><img src={upwork} alt="upwork" /></a>}
                                </Icons>
                            </Third>
                        </Left>
                        <Right>
                            <Fourth>
                                <p className="titleCard">{Data.cardDetails.bio}</p>
                                <p className="textBio">{selectedLang === "en" ? card.bio_en : card.bio}</p>
                            </Fourth>
                            <Fifth>
                                <p className="titleCard">{Data.cardDetails.tools}</p>
                                {card.skills.TECH_SKILL && card.skills.TECH_SKILL.map((tool, i) => (
                                    <button key={i}>{tool}</button>
                                ))
                                }
                            </Fifth>
                            <Sixth>
                                <p className="titleCard">{Data.cardDetails.skills}</p>
                                {card.skills.SOFT_SKILL && card.skills.SOFT_SKILL.map((tool, i) => (
                                    <button key={i}>{tool}</button>
                                ))
                                }
                            </Sixth>
                        </Right>

                    </CardDetailsTextWrapper>
                </CardDetailsBlock>
            </CardDetailsSection>
        </>

    );
}

export default CardDetails;

