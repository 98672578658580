import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Headline50 } from '../FontStyle';

const ClientsWprapper = styled.div`
   &.innovation{
    padding:80px 0px 150px;
   } 
   &.b2b{
    padding:0px; 
   }
   .b2bSliderLogos{
      height: 330px;
    }
    .innovationSliderLogos{
      height: 350px;
    }
   @media (max-width:768px){
    .innovationSliderLogos,.b2bSliderLogos{
        height: 150px;
    }
   }
   @media (max-width:1440px){ 
    &.innovation{
        padding:50px 0px 80px;
    } 
   }
`
const Title = styled.div`
    width:80%;
    margin:0 auto;
    padding-bottom:60px;
    @media (max-width:768px){
        width:100%;
        padding:0px 24px 60px;
        box-sizing:border-box;
        &>p {
            padding-left: 16px;
        }
    }
    @media (max-width: 425px){
        &>p{
            padding-left: 0px;
        }
    }
`
const LogosMove = styled.div`
    max-width: 100%;
    background:${props => props.background};
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
`
const LogosTransitionTop = styled.div`
    width: 100%;
    height: 150px;
    background: url(${props => props.logos}) repeat-x;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: scroll-left 200s linear infinite;
    -o-animation: scroll-left 200s linear infinite;
    animation: scroll-left 200s linear infinite;
    background-size: cover;
    @media (max-width:768px){
        height: 120px;
    }
    @keyframes scroll-left {
        100% {
            background-position: -10000px 0;
        }
    }
`
const LogosTransitionBottom = styled.div`
    width: 100%;
    height: 150px;
    background: url(${props => props.logos}) repeat-x;
    background-position: 0 0;
    position: absolute;
    right: 0;
    bottom: 0;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: scroll-right 200s linear infinite;
    -o-animation: scroll-right 200s linear infinite;
    animation: scroll-right 200s linear infinite;
    background-size: cover;
    &.logosBtb{
        display:none;
    }
    @media (max-width:768px){
        height: 120px;
        &.logosBtb{
            display:block;
        }
        & {
            display: none;
        }
    }
    @keyframes scroll-right {
        100% {
            background-position: 10000px 0;
        }
        
    }
`
const Clients = ({ subDomain, title, logos, background }) => {
    useEffect(() => { console.log(logos) }, [logos])
    return (
        <ClientsWprapper className={`innovation ${subDomain}`}>
            {subDomain !== "b2b" &&
                <Title>
                    <Headline50 className="margin">{title}</Headline50>
                </Title>
            }
            <LogosMove className={subDomain === "innovation" ? "innovationSliderLogos" : "b2bSliderLogos"} background={background}>
                <LogosTransitionTop logos={logos} />
                <LogosTransitionBottom logos={logos} className={subDomain === "b2b" && "logosBtb"} />
            </LogosMove >
        </ClientsWprapper >);
}

export default Clients;