import React from 'react';
import styled from 'styled-components';
import Data from '../../Data';

const SloganMoveWrapper = styled.div`
   background:#183FF3;
   overflow: hidden;
   padding:40px 0px;
   display:flex;
   align-items:center;
 
   .text{
    width: 2000px;
    font-size:40px;
    font-weight:bold;
    color:#F8F8F8;
    margin: 0;
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -moz-animation: left 2s linear infinite;
    -webkit-animation: left 2s linear infinite;
    animation: left 20s linear infinite;
    @-moz-keyframes left {
        0% {
            -moz-transform: translateX(100%);
        }
        100% {
            -moz-transform: translateX(-100%);
        }
    }
    
    @-webkit-keyframes left {
        0% {
            -webkit-transform: translateX(100%);
        }
        100% {
            -webkit-transform: translateX(-100%);
        }
    }
    
    @keyframes left {
        0% {
            -moz-transform: translateX(100%);
            -webkit-transform: translateX(100%);
            transform: translateX(100%);
        }
        100% {
            -moz-transform: translateX(-100%);
            -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
        }
    }
}

`

const SloganMove = () => {
    return (
        <SloganMoveWrapper>
            <div>
                <p className="text">{Data.sloganMove}</p>
            </div>
        </SloganMoveWrapper>
    );
}

export default SloganMove;