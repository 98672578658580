import React from 'react';
import styled from 'styled-components';
import FilterSideBar from './FilterSideBar';
import Cards from '../Cards/Cards';
import useCounter from '../Hooks/useCounter';
import Data from '../../Data';
import { Headline74} from '../FontStyle';
import CountUp from "react-countup";


import background from '../../img/Filter/background.png'
import backgroundMobile from '../../img/Filter/background-mobile.png'
import useOnScreen from '../Hooks/useOnScreen';
import useFilter from '../Hooks/useFilter';


const FilterBlock = styled.div`
    width:100%;
    background-image:url(${background});
    background-size: 100%;
    background-repeat: no-repeat;
    @media (max-width:768px){
        background-image:url(${backgroundMobile});
    }
    @media (min-width:426px) and (max-width:768px){
        background-size: 100% 20%;
    }
`
const Wrapper = styled.div`
    width:80%;
    margin:0 auto;
    box-sizing: border-box;
    @media (max-width:425px){
        width:100%;
        padding:0px 24px !important;
    }
    @media (max-width:768px){
        width:100%;
        padding:0px 40px;
    }
`
const TitleWrapper = styled.div`
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding:100px 0px;
    .heading{
       width:50%;
       margin:0px;
       @media (max-width:768px){
        display:none;
       }
    }
    @media (max-width:768px){
       padding:63px 0px 40px 0px;
    }
`
const FilterSection = styled.div`
    position:relative;
    .clear{
        clear: both;
    }
`
const Title = styled.div`
    color:#3A3A3A;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    padding-bottom:74px;
    @media (max-width:768px){
       display:none;
    }
`
const RightTitle = styled.div`
    width:50%;
    display:flex;
    justify-content: flex-end;
    align-items: center;
    .number{
        color:#FBD132 !important;
        margin:0px;
        margin-right:13px;
    }
    @media (max-width:768px){
        width:auto;
        margin:0 auto;
        .number{
            width:70px;
            font-size:59px;
        }
    }
`
const SpanTextRight = styled.span`
    white-space:pre;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 31px;
    color:#3A3A3A;
    font-family: 'Montserrat';
    @media (max-width:500px){
        font-size: 24px; 
        line-height: 26px;
    }
    @media (max-width:375px){
        font-size: 22px; 
        white-space:pre-line;
    }
`


const Filter = ({ students, subDomain }) => {
    const [filterCard, filtered, clickedElement, color] = useFilter(students)
    const [counterList, handleMore, hideButton, resetCounter] = useCounter(filtered,4);
    const [setRef, visible] = useOnScreen({ rootMargin: '0px', })

    return (
        <FilterBlock>
            <Wrapper>
                <TitleWrapper >
                    <Headline74 className="heading">{Data.filter.title}</Headline74>
                    <RightTitle ref={setRef}>
                        <CountUp end={students && students.length} start={visible ? 1 : null}>
                            {({ countUpRef }) => (
                                <Headline74 className="number" ref={countUpRef} />
                            )}
                        </CountUp>
                        <SpanTextRight>{Data.filter.text}</SpanTextRight>
                    </RightTitle>
                </TitleWrapper>
                <Title>{Data.filter.subHeading}</Title>
                <FilterSection>
                    <FilterSideBar subDomain={subDomain} handleClick={filterCard} clickedElement={clickedElement} resetCounter={resetCounter}/>
                    <Cards filtered={counterList} handleMore={handleMore} hideButton={hideButton} color={color} subDomain={subDomain} />
                    <div className="clear"/>
                </FilterSection>
            </Wrapper>
        </FilterBlock >
    );
}

export default Filter;

