import React from 'react';
import { Swiper, Slide } from 'react-dynamic-swiper'
import 'react-dynamic-swiper/lib/styles.css'
import { SingleBox } from './CardBox';
import { ProjectImages } from './Innovation/OurLatestWork';
import { ModalSliderData } from './InnovationLatesWork/ModalSlider';
import SliderTestimonials from './SliderTestimonials/SliderTestimonials';
import GalleryImage from './InnovationLatesWork/GalleryImage';


const Slider = ({ slideImages, src, handlePopUp, whereIsIt, centeredSlides, loop,projectToShow}) => {

    return (
        <div className="swiper-slider">
            <Swiper
                swiperOptions={{
                    slidesPerView: 2,
                    grabCursor: true,
                    spaceBetween: 32,
                    loop: loop === false ? loop : true,
                    centeredSlides: centeredSlides === false ? centeredSlides : true,
                    breakpoints: {
                        425: {
                            slidesPerView: 1.3,
                            spaceBetween: 0,
                            centeredSlides: true,
                        },
                        768: {
                            slidesPerView:  1.6,
                            spaceBetween: 16,
                        },
                    }
                }}
                pagination={false}
            >
                {slideImages && slideImages.map((slideImage, index) => (
                    <Slide key={index}>
                        {whereIsIt === "projects" ? <SingleBox handlePopUp={handlePopUp} card={slideImage} />
                            : (whereIsIt === "latesWorkModalGallery") ? <ModalSliderData handlePopUp={handlePopUp} data={slideImage} />
                                : (whereIsIt === "SliderGallery") ? <GalleryImage linkImage={require(`../img/LatestWork/${projectToShow.id}/SliderGallery/${slideImage.id}.png`).default}/>
                                    : (whereIsIt === "hiring") ? <SliderTestimonials testimonial={slideImage} src={src} />
                                        : <ProjectImages card={slideImage} index={index} />
                        }
                    </Slide>
                ))}
            </Swiper>
        </div>
    );
}

export default Slider;
