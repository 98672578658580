import React from 'react';
import usePopUp from '../Hooks/usePopUp';
import styled from 'styled-components';
import Data from '../../Data';
import { FilterMenu } from './FilterMenu';
import filter from '../../img/Filter/filter.png'
import { Dots } from '../icons';

const MobileDropDownMenu = styled.div`
   display:none;
   @media (max-width:768px){
    display:block;
    padding-bottom:45px;
   }
`
const DropDownText = styled.div`
    display: grid;
    grid-template-columns: 0.2fr 1fr 0.2fr;
    align-items: center;
    grid-gap: 10px;
    background: #ffffff;
    padding:0px 15px;
    .academy{
        font-size:18px;
        font-weight:bold;
        color:#3A3A3A;
    }
    img{
        justify-self: start;
    }
    svg{
        justify-self: end;
    }
`
const DropDownMenu = styled.div`
    &.active{
        position: static;
        opacity: 1;
        transition: opacity 1s linear;
    }
    &.fadeOut {
        position:absolute;
        opacity: 0;
    }
    ul{
       text-align: center;
       padding:0px;
      }

`
const DropDownMobileFilter = ({ filterMenuList, handleClick, clickedElement, resetCounter, subDomain }) => {
    const [popUp, handlePopUp] = usePopUp(false)
    return (
        <MobileDropDownMenu>
            <DropDownText onClick={handlePopUp} >
                <img src={filter} alt="" />
                <p className="academy">{Data.filterMenu.find((el, i) => el.id === clickedElement).filterName}</p>
                <Dots fill={popUp ? "#FBD132" : "#161616"} />
            </DropDownText>
            <DropDownMenu className={popUp ? "active" : "fadeOut"} onClick={handlePopUp}>
                <FilterMenu
                    filterMenuList={filterMenuList}
                    handleClick={handleClick}
                    clickedElement={clickedElement}
                    resetCounter={resetCounter}
                    subDomain={subDomain} />
            </DropDownMenu>

        </MobileDropDownMenu>
    );
}

export default DropDownMobileFilter;